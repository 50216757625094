import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, InputLabel } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { Autocomplete, Stack, TextField } from "@mui/material";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isEmpty, find, cloneDeep } from "lodash";
import { dateFormat, findObjByKey } from "../../../helpers/common";
import { ERROR_MESSAGE } from "../../../messages/index";
import { useValue } from "../../../hooks/useBatchCommon";
import { fetchECListByGroupdId } from "../../../services/api.sfBatch";
import Datetime from "react-datetime";
import Moment from "moment";

export default function FilterCondition(props) {
  const { batchCode, title, desc, model, cusSchema,
    conditions,
    listGroups,
    onAddFilterCondition,
    batchFilterCondition,
    onAddBatchFilterCondition,
    errors,
    listFilterType,
    setBetweenError
  } = props;
  const dispatch = useDispatch();
  const [ecList, setEcList] = useState([]);
  const mapCusSchema = cusSchema.length ? cusSchema.map((el) => new Object({ label: el.value, value: el.label, type: el.type })) : [];

  useEffect(() => {
    async function callECList(id) {
      const res = await fetchECListByGroupdId(id, dispatch);
      if (res.isOk) {
        setEcList(res.data);
      }
    }
    if (batchFilterCondition?.list_group_id) {
      callECList(batchFilterCondition.list_group_id);
    }
  }, [batchFilterCondition?.list_group_id]);

  useEffect(() => {
    const data = cloneDeep(conditions);
    const temp1 = data.filter(el => el.filter_type.includes("between"))
    temp1.forEach(el => {
      if (!el.filter_value.value_1 || !el.filter_value.value_2 || el.filter_value.value_2 <= el.filter_value.value_1) {
        setBetweenError(true);
        return;
      }
      setBetweenError(false);
    });
  }, [conditions]);
  function strToObj(str) {
    const properties = str.split("|");
    const obj = { type: properties[0], value_1: properties[1], value_2: properties[2] };
    return obj;
  }
  useEffect(() => {
    if (conditions.length > 1) {
      const tmp2 = [];
      const tmp = cloneDeep(conditions);
      let curIdx = [];
      tmp.forEach((el, index) => {
        switch (el.filter_type) {
          case ">=": {
            if (tmp[index + 1].filter_type === "<=" && tmp[index + 1].item === tmp[index].item) {
              const type = getItemType(el.item);
              const value_1 = parseInt(tmp[index].filter_value);
              const value_2 = parseInt(tmp[index + 1].filter_value);
              tmp2.push({
                id: el.id,
                company_id: el.company_id,
                item_type: type, item: tmp[index].item,
                filter_type: "between",
                filter_value: { type: type, value_1: value_1, value_2: value_2 },
                type: el.type
              })
              curIdx.push(index + 1);
            } else {
              tmp2.push(el);
            }
            break;
          }
          case "str_gte": {
            if (tmp[index + 1].filter_type === "str_lte" && tmp[index + 1].item === tmp[index].item) {
              const type = getItemType(el.item);
              const value_1 = tmp[index].filter_value
              const value_2 = tmp[index + 1].filter_value
              tmp2.push({
                id: el.id,
                company_id: el.company_id,
                item: el.item,
                item_type: type,
                filter_type: "between",
                filter_value: { type: type, value_1: value_1, value_2: value_2 },
                type: el.type
              })
              curIdx.push(index + 1);
            } else {
              tmp2.push(el);
            }
            break;
          }
          case "after": {
            if (tmp[index + 1].filter_type === "before" && tmp[index + 1].item === tmp[index].item) {
              const type = getItemType(el.item);
              const value_1 = tmp[index].filter_value
              const value_2 = tmp[index + 1].filter_value
              tmp2.push({
                id: el.id,
                company_id: el.company_id,
                item: tmp[index].item,
                item_type: type,
                filter_type: "between",
                filter_value: { type: type, value_1: value_1, value_2: value_2 },
                type: el.type
              })
              curIdx.push(index + 1);
            } else {
              tmp2.push(el);
            }
            break;
          }
          default: {
            // compare current index to between value index, if not equal push element to new conditions array
            if (!curIdx.includes(index)) {
              const type = getItemType(el.item);
              if (el.filter_type === "not_between" && typeof el.filter_value === "string") {
                const newFilterVal = strToObj(el.filter_value);
                el.filter_value = newFilterVal;
              }
              tmp2.push({ ...el, item_type: type })
            }
            break;
          }
        }
      })
      onAddFilterCondition(tmp2);
    }
  }, [])

  //Function handle when between filter is selected
  function handleChangeBetweenCondition(e, position, index, type) {
    let currentType = "str";
    if (type?.toLowerCase().includes("double") || type?.toLowerCase().includes("number")) {
      currentType = "number";
    } else if (type?.toLowerCase().includes("date")) {
      currentType = "date";
    }
    const value = currentType === "number" && e?.target?.value ? Math.floor(e?.target?.value) : e?.target?.value ?? e;
    const tmp = cloneDeep(conditions);
    const currentValue = tmp[index].filter_value ? tmp[index].filter_value : { type: "", value_1: "", value_2: "" };
    if (position === 1) {

      handleChangeCondition(index, "filter_value", { ...currentValue, type: currentType, value_1: value });
    } else {
      handleChangeCondition(index, "filter_value", { ...currentValue, type: currentType, value_2: value });
    }
  }

  function handleChangeDate(index, value, position) {
    if (position) {
      const newValue = Moment(value).format('YYYY-MM-DD');
      handleChangeBetweenCondition(newValue, position, index, "date");
    } else {
      const newValue = Moment(value).format('YYYY-MM-DD');
      handleChangeCondition(index, "filter_value", newValue);
    }
  }

  function getFilterOptions(data) {
    if (data?.item_type?.toLowerCase().includes("double") || data?.item_type?.toLowerCase().includes("number")) {
      return listFilterType.num_type;
    }
    if (data?.item_type?.toLowerCase().includes("date")) {
      return listFilterType.date_type;
    }
    return listFilterType.str_type;
  }

  function getInputType(data) {
    const type = getItemType(data.item);
    return type.includes("number") ? "number" : "text"
  }

  function getItemType(value) {
    const temp = find(cusSchema, { label: value });
    const type = temp?.type?.toLowerCase().includes("number") || temp?.type?.toLowerCase().includes("double") ? "number" : temp?.type?.toLowerCase().includes("date") ? "date" : "str";
    return type;
  }

  function findFilterTypeData(key, data) {
    const type = getItemType(data.item);
    const filterType = type === "number" ? listFilterType.num_type : type === "date" ? listFilterType.date_type : listFilterType.str_type;
    const obj = find(filterType, { key });
    if (!obj) {
      return null;
    }
    return obj;
  }


  // Function handle when salesforce condition change value
  function handleChangeCondition(index, name, value) {
    const data = [...conditions];
    if (name === "filter_type") {
      data[index][name] = !value ? "" : value.key;
      data[index]["filter_value"] = ""; // set filter value to "" when user change filter type
    } else if (name === "item") {
      data[index][name] = !value ? "" : value;
      data[index]["filter_value"] = ""; // set filter value to "" when user change filter item
      data[index]["filter_type"] = ""; // set filter type to "" when user change filter item
    } else {
      data[index][name] = value;
    }

    onAddFilterCondition(data);
  }

  /**
  * Push data thought props
  * @param {string} name 
  * @param {string} value 
  */
  function emitData(name, value) {
    const val = {
      ...batchFilterCondition,
      [name]: value,
      batch_code: model.batch_code,
      batch_name: model.batch_name
    };
    onAddBatchFilterCondition(val);
  }

  // Function handle when salesforce condition change value
  function handleChangeBatchValue(e) {
    const { name, value } = e.target;
    const val = useValue(name, value);
    onAddBatchFilterCondition({
      ...batchFilterCondition,
      [name]: val,
      batch_code: model.batch_code,
      batch_name: model.batch_name
    });
  }

  function handleAddCondition() {
    const data = [...conditions, { item: "", filter_type: "", filter_value: "", item_type: "", filter_label: "" }];
    onAddFilterCondition(data);
  }

  function deleteCondition(index) {
    let result = cloneDeep(conditions);
    result.splice(index, 1);
    return onAddFilterCondition(result);
  }

  function findSelectedOption(val, type = 0) {
    const options = type === 0 ? listGroups : ecList;
    return findObjByKey(options, "key", parseInt(val));
  }

  function handleOnSelected(name, value) {
    emitData(name, value);
  }

  function requiredValue(type, value) {
    return (!["blank", "not_blank"].includes(type) && !value);
  }

  function checkBetweenValue(data) {
    const type = getItemType(data.item);
    if (type === "number" && data.filter_value.value_2 <= data.filter_value.value_1) {
      return true;
    }
    return false;
  }

  function getLabel(data){
    const item = find(mapCusSchema,{value: data.item});
    return item;
  }

  const titleRender = <>
    <GridItem xs={12}>
      <h4 className="text-bold text-dark-grey">
        {title}
      </h4>
      <p className="text-grey">{desc}</p>
    </GridItem></>;

  const filterConditionRender = <>
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={4} className="text-grey">
              項目名
            </GridItem>
            <GridItem xs={3} className="text-grey">
              条件
            </GridItem>
            <GridItem xs={4} className="text-grey">
              値
            </GridItem>
            <GridItem xs={1}></GridItem>
          </GridContainer>
          {conditions.map((el, index) => {
            return (
              <GridContainer key={index} alignItems="center">
                <GridItem xs={4}>
                  <Autocomplete
                    disablePortal
                    value={getLabel(el) || ""}
                    options={!isEmpty(mapCusSchema) ? mapCusSchema : []}
                    renderInput={(params) => {
                      return <TextField
                        helperText={!el.item ? ERROR_MESSAGE.REQUIRED : ""}
                        variant="standard" {...params}
                        error={!el.item ? true : false}
                      />;
                    }}
                    onChange={(_e, val) => {
                      handleChangeCondition(index, "item", val?.value);
                      handleChangeCondition(index, "item_type", val?.type);
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <Autocomplete
                    disablePortal
                    value={findFilterTypeData(el.filter_type, el) || null}
                    options={getFilterOptions(el) || []}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        placeholder="条件"
                        helperText={!findFilterTypeData(el.filter_type, el) ? ERROR_MESSAGE.REQUIRED : ""}
                        error={!findFilterTypeData(el.filter_type, el) ? true : false}
                      />
                    )}
                    onChange={(_e, val) => {
                      // handleChangeCondition(index, "filter_label", val.label);
                      handleChangeCondition(index, "filter_type", val);
                    }}
                  />
                </GridItem>
                <GridItem xs={4} className="batch-filter-value">
                  {
                    el?.filter_type?.includes("between") ?
                      <Stack direction="row" gap={2}>
                        {el?.item_type?.toLowerCase().includes("date") ?
                          <React.Fragment>
                            <Datetime
                              closeOnSelect
                              dateFormat="YYYY-MM-DD"
                              timeFormat={false}
                              onChange={(e) => handleChangeDate(index, e, 1)}
                              renderInput={(props) => {
                                return <input
                                  {...props}
                                  placeholder="値1"
                                  value={el?.filter_value?.value_1 ?? ""}
                                />;
                              }}
                            />
                            <Datetime
                              closeOnSelect
                              dateFormat="YYYY-MM-DD"
                              timeFormat={false}
                              isValidDate={(currentDate) => {
                                if (currentDate.isAfter(Moment(el?.filter_value?.value_1 ?? ""))) {
                                  return true;
                                }
                                return false;
                              }}
                              onChange={(e) => handleChangeDate(index, e, 2)}
                              className="date-picker-right"
                              renderInput={(props) => {
                                return <input
                                  {...props}
                                  placeholder="値2"
                                  value={el?.filter_value?.value_2 ?? ""}
                                />;
                              }}
                            />
                          </React.Fragment>
                          : <React.Fragment>
                            <CustomInput
                              error={checkBetweenValue(el) || requiredValue(el.filter_type, el.filter_value?.value_1)}
                              helperText={requiredValue(el.filter_type, el.filter_value) ? ERROR_MESSAGE.REQUIRED : checkBetweenValue(el) ? "value 2 must be greater than value 1" : ""}
                              labelText="値1"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                disabled: el.filter_type === "blank" || el.filter_type === "not_blank",
                                type: getInputType(el),
                                value: el?.filter_value?.value_1 || "",
                                onChange: (e) => handleChangeBetweenCondition(e, 1, index, el?.item_type)
                              }}
                            />
                            <CustomInput
                              error={checkBetweenValue(el) || requiredValue(el.filter_type, el.filter_value?.value_2)}
                              helperText={requiredValue(el.filter_type, el.filter_value) ? ERROR_MESSAGE.REQUIRED : checkBetweenValue(el) ? "value 2 must be greater than value 1" : ""}
                              labelText="値2"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                disabled: el.filter_type === "blank" || el.filter_type === "not_blank",
                                type: getInputType(el),
                                value: el?.filter_value?.value_2 || "",
                                onChange: (e) => handleChangeBetweenCondition(e, 2, index, el?.item_type)
                              }}
                            />
                          </React.Fragment>
                        }
                      </Stack>
                      :
                      el?.item_type?.toLowerCase().includes("date") ?
                        <Datetime
                          closeOnSelect
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          onChange={(e) => handleChangeDate(index, e)}
                          renderInput={(props) => {
                            return <input
                              {...props}
                              placeholder="値"
                              value={el?.filter_value}
                            />;
                          }}
                        /> :
                        <CustomInput
                          error={requiredValue(el.filter_type, el.filter_value)}
                          helperText={requiredValue(el.filter_type, el.filter_value) ? ERROR_MESSAGE.REQUIRED : ""}
                          labelText="値"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            disabled: el.filter_type === "blank" || el.filter_type === "not_blank",
                            type: getInputType(el),
                            value: el.filter_value,
                            onChange: (e) => handleChangeCondition(index, "filter_value", e.target.value)
                          }}
                        />
                  }
                </GridItem>
                <GridItem xs={1} align="right">
                  <Button
                    justIcon
                    color="danger"
                    className="btn-schedule-setting"
                    onClick={() => deleteCondition(index)}
                  >
                    <Delete />
                  </Button>
                </GridItem>
              </GridContainer>
            );
          })}
        </GridItem>
        <GridItem xs={12} align="right">
          <Button
            onClick={handleAddCondition}
            justIcon
            className="btn-schedule-setting"
          >
            <Add />
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
  </>;
  const periodRender = <>
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={6}>
          <InputLabel className="text-grey">開始日</InputLabel>
          <CustomInput
            success={isEmpty(batchFilterCondition?.start_date)}
            error={batchFilterCondition?.start_date ? true : false}
            type="datetime"
            dateFormat="YYYY-MM-DD"
            timeFormat={true}
            closeOnSelect={false}
            helperText={errors?.start_date}
            formControlProps={{ fullWidth: true }}
            onDateChange={(e) => handleChangeBatchValue(e)}
            inputProps={{
              name: "start_date",
              placeholder: "YYYY-mm-dd HH:mm",
              maxLength: 255,
              value: batchFilterCondition?.start_date ? dateFormat(batchFilterCondition?.start_date) : "",
              type: "text",
              autoComplete: "off"
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <InputLabel className="text-grey">終了日</InputLabel>
          <CustomInput
            success={isEmpty(batchFilterCondition?.end_date)}
            error={batchFilterCondition?.end_date ? true : false}
            type="datetime"
            dateFormat="YYYY-MM-DD"
            timeFormat={true}
            closeOnSelect={false}
            helperText={errors?.end_date}
            formControlProps={{ fullWidth: true }}
            onDateChange={(e) => handleChangeBatchValue(e)}
            inputProps={{
              name: "end_date",
              placeholder: "YYYY-mm-dd HH:mm",
              maxLength: 255,
              value: batchFilterCondition?.end_date ? dateFormat(batchFilterCondition.end_date) : "",
              type: "text",
              autoComplete: "off"
            }}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  </>;

  const listCondition = <>
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={6}>
          <Box sx={{ py: 2 }}>
            <InputLabel className="text-grey">
              リストグループ
            </InputLabel>
          </Box>
          <Autocomplete
            id="list_group_id"
            disablePortal
            value={findSelectedOption(batchFilterCondition?.list_group_id) || null}
            options={!isEmpty(listGroups) ? listGroups : []}
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.key}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                error={!isEmpty(errors?.list_group_id)}
                helperText={errors?.list_group_id ?? ""}
                variant="standard" {...params}
              />
            )}
            onChange={(_e, val) => handleOnSelected("list_group_id", val.key)}
          />
        </GridItem>
        <GridItem xs={6}>
          <Box sx={{ py: 2 }}>
            <InputLabel className="text-grey">リスト</InputLabel>
          </Box>
          <Autocomplete
            disablePortal
            defaultValue={null}
            value={findSelectedOption(batchFilterCondition?.list_id, 1) || null}
            options={ecList}
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.key}>
                {option.label}
              </Box>
            )}

            renderInput={(params) => (
              <TextField
                error={!isEmpty(errors?.list_id)}
                helperText={errors?.list_id ?? ""}
                variant="standard" {...params} />
            )}
            onChange={(_e, val) => handleOnSelected("list_id", val ? val.key : "")}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  </>;

  function generateView() {
    // IMPORT EC CUSTOMER LIST
    if (['BATCH_01', 'BATCH_04'].includes(batchCode)) {
      //return start end
      return <>{titleRender} {filterConditionRender}</>;
    }
    //Customer Update KINTONE, SALESFORCE
    if (['BATCH_02', 'BATCH_05'].includes(batchCode)) {
      if (model.schedule == 1) {
        return <>{titleRender} {periodRender}</>;
        //return start end
      }

      return <>{titleRender} {listCondition}</>;
    }

    //HISTORY Update KINTONE, SALESFORCE
    if (['BATCH_03', 'BATCH_06'].includes(batchCode) && model.schedule == 1) {
      //return start end
      return <>{titleRender} {periodRender}</>;
    }
  }

  return (
    <>
      {generateView()}
    </>
  );
}
