import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import { cloneDeep, isEmpty, isEqual, isNil, isUndefined, remove } from "lodash";
import { findObjByKey } from "../../../helpers/common";
import { ERROR_MESSAGE } from "../../../messages/index";
import {
  clearSFItem,
  deleteMappingData,
  changeMappingLogicSelect,
  mappingLogicData,
} from "../../../business/mappingLogic";

import {
  fetchCusMapping,
  fetchCusMappingInfo,
  fetchCusSchema,
  fetchUpdateCusMapping,
} from "../../../services/api.salesforce";


export default function SFCusMapping() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state);
  const { authentication: { user: { company_id } } } = userInfo;
  const [open, setOpen] = useState(true);
  const [mappingData, setMappingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [salesforceCustomerObj, setSalesforceCustomerObj] = useState([]);
  const [customerObj, setCustomerObj] = useState("");
  const [evercallData, setEvercallData] = useState([]);
  const [sfData, setSfData] = useState([]);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isFirstRun, setIsFirstRun] = useState(true);

  const [filterCondition, setFilterCondition] = useState({
    ec_item: "",
    ec_name: "",
    sf_item: "",
    sf_name: "",
  });

  useEffect(() => {
    document.title = "Salesforce顧客項目マッピング";
    fetchAllData();
  }, []);

  async function fetchAllData() {
    const resMapping = await fetchCusMapping();
    setMappingData(resMapping.data);
    setFilteredData(resMapping.data);

    const resObj = await fetchCusMappingInfo(dispatch);
    if (resObj.isOk) {
      setSalesforceCustomerObj(resObj.data.salesforce_objects);
      setEvercallData(resObj.data.ec_customer_schema);
    }

    if (!isEmpty(resMapping.salesforce_object)) {
      setCustomerObj(resMapping.salesforce_object);
    } else {
      setOpen(false);
    }
  }

  useEffect(() => {
    async function fetchCustomerSchemaApi() {
      const res = await fetchCusSchema({ sf_customer_obj: customerObj }, dispatch);
      setSfData(res.data.data);
      if (isFirstRun) {
        setOpen(false);
        return setIsFirstRun(false);
      }
      setFilteredData((prevState) => clearSFItem(prevState));
      setMappingData((prevState) => clearSFItem(prevState));
      setOpen(false);
    }
    if (customerObj) {
      setOpen(true);
      fetchCustomerSchemaApi();
    }
  }, [customerObj]);



  // Function handle when select change value
  function handleChangeSelect(idx, key, value) {
    setMappingData((state) =>
      changeMappingLogicSelect(state, idx, key, value)
    );
    setFilteredData((state) =>
      changeMappingLogicSelect(state, idx, key, value)
    );
  }

  // Function add customer mapping
  function addMapping() {
    setMappingData((state) => mappingLogicData(state, company_id));
    setFilteredData((state) => mappingLogicData(state, company_id));
  }

  // Function delete customer mapping
  function deleteMapping(index) {
    setMappingData((state) => deleteMappingData(state, index));
    setFilteredData((state) => deleteMappingData(state, index));
  }

  async function handleUpdate() {
    setIsLoadingBtn(true);
    await fetchUpdateCusMapping({ customer_mapping: mappingData, sf_customer_obj: customerObj }, dispatch);
    setIsLoadingBtn(false);
  }

  // Function generate header for table
  function headerTypeData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        EVERCALL
      </h6>,
      "",
      <GridContainer
        key="sf_name"
        direction="row"
        justifyContent="space-between"
      >
        <h6 key="sf_name" className="color-blue text-bold">
          SALESFORCE
        </h6>
        <Box sx={{ mr: 2 }}>
          <h6 key="sf_name" className="color-blue text-bold">
            テーブル名
          </h6>
        </Box>
      </GridContainer>,
      <Autocomplete
        key="salesforce_obj"
        disablePortal
        value={customerObj || null}
        options={salesforceCustomerObj}
        renderInput={(params) => <TextField variant="standard" {...params} />}
        onChange={(event, newValue) => handleChangeCustomerObj(newValue)}
      />,
      "",
    ];
  }

  // Function generate header for table
  function headerData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        項目名
      </h6>,
      <h6 key="ec_item" className="color-red text-bold">
        項目KEY
      </h6>,

      <h6 key="sf_name" className="color-blue text-bold">
        項目名
      </h6>,
      <h6 key="sf_item" className="color-blue text-bold">
        項目KEY
      </h6>,
      <h6 key="action"></h6>,
    ];
  }

  function handleChangeCustomerObj(value) {
    setCustomerObj(value);
  }

  // Function generate row search for table
  function searchData() {
    return ["ec_item", "ec_name", "sf_item", "sf_name"].map((item) => (
      <CustomInput
        key={item}
        formControlProps={{
          fullWidth: true,
          className: "input-search",
        }}
        inputProps={{
          onChange: (e) => {
            filterTables(item, e.target.value);
          },
          type: "text",
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    ));
  }

  function findSelectedItem(val, key = "ec_item") {
    const items = key === "ec_item" ? evercallData : sfData;
    const obj = findObjByKey(items, "label", val);
    if (isUndefined(obj)) {
      return null;
    }

    return obj;
  }

  function findSelectedEcItem(val) {
    const obj = evercallData.find((item) => {
      if (val.head_id) {
        return item.label === val.ec_item && item.head_id === val.head_id;
      } else {
        return item.label === val.ec_item;
      }
    });
    if (isUndefined(obj)) {
      return null;
    }
    return obj;
  }

  function deleteSelectedItem(key, label) {
    const options = key === "ec_item" ? evercallData : sfData;
    let result = cloneDeep(options);
    const copyData = [...filteredData];

    for (let item of copyData) {
      remove(result, (e) => {
        const condition =
          e.label !== label && e.label === item[key];

        if (key === "ec_item") {
          const resHeadId = isNil(e.head_id) ? "" : e.head_id;
          const itemHeadId = isNil(item["head_id"]) ? "" : item["head_id"];
          return condition && resHeadId === itemHeadId;
        }

        return condition;
      });
    }

    return result;
  }

  // Function generate data body table
  function renderDataTable() {
    const data = [...filteredData];
    let result = data.map((item, index) => {
      const everCall = evercallData.find((e) => {
        if (item.head_id) {
          return e.label === item.ec_item && e.head_id === item.head_id;
        } else {
          return e.label === item.ec_item;
        }
      });
      const salesForce = findObjByKey(sfData, "label", item.salesforce_item);

      return [
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedEcItem(item) || null}
          options={deleteSelectedItem("ec_item", item.ec_item)}
          groupBy={(option) => option.head_name}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return <TextField
              helperText={!item.ec_item ? ERROR_MESSAGE.REQUIRED : ""}
              variant="standard" {...params}
              error={!item.ec_item ? true : false}
            />;
          }}
          onChange={(_e, v) => handleChangeSelect(index, "ec_item", v)}
        />,
        !everCall ? "" : (everCall.head_name ? everCall.head_name + "_" : "") + everCall.label,
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedItem(item.salesforce_item, "salesforce_item")}
          options={deleteSelectedItem("salesforce_item", item.salesforce_item)}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return <TextField
              helperText={!item.salesforce_item ? ERROR_MESSAGE.REQUIRED : ""}
              variant="standard" {...params}
              error={!item.salesforce_item ? true : false} />;
          }}
          onChange={(_e, v) => handleChangeSelect(index, "salesforce_item", v)}
        />,
        !salesForce ? "" : salesForce.label,
        <Button
          key={item.id}
          justIcon
          color="danger"
          className="btn-history-setting"
          onClick={() => deleteMapping(index)}
        >
          <Delete />
        </Button>,
      ];
    });

    // If filter condition is not empty, filter data
    if (filterCondition["ec_item"] !== "") {
      const lowerCaseData = filterCondition["ec_item"].toLowerCase();
      result = result.filter(
        (e) =>
          e[0].props.value?.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ec_name"] !== "") {
      const lowerCaseData = filterCondition["ec_name"].toLowerCase();
      result = result.filter(
        (e) => e[1].toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["sf_item"] !== "") {
      const lowerCaseData = filterCondition["sf_item"].toLowerCase();
      result = result.filter(
        (e) =>
          e[2].props.value?.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["sf_name"] !== "") {
      const lowerCaseData = filterCondition["sf_name"].toLowerCase();
      result = result.filter(
        (e) => e[3].toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // Add search row to table
    result.unshift(searchData());

    // Add header to table
    result.unshift(headerData());

    // Add header type to table
    result.unshift(headerTypeData());

    return result;
  }

  // Function handle when input search change data
  function filterTables(key, value) {
    setFilterCondition({ ...filterCondition, [key]: value });
  }

  // Function to disable button
  function disabledAddFilter(salesforce, evercall, filterData) {
    if (!salesforce || !evercall) {
      return false;
    }

    let selectedEvercall = [];
    let selectedsaleforce = [];
    for (let item of filterData) {
      selectedEvercall.push(item.ec_item);
      selectedsaleforce.push(item.salesforce_item);
    }

    // If data of filter data is equal evercall or salesforce data
    if (
      selectedEvercall.length === evercall.length ||
      selectedsaleforce.length === salesforce.length
    ) {
      return true;
    }

    return false;
  }

  function enableBtn() {
    if (!customerObj || mappingData.length === 0) {
      return true;
    }

    return mappingData.some(
      (el) => el.ec_item === undefined || el.ec_item === "" || el.ec_item === null || el.salesforce_item === undefined || el.salesforce_item === "" || el.salesforce_item === null
    );
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer direction="row">
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h3 className="text-bold text-dark-grey">顧客項目マッピング</h3>
              <GridContainer direction="row">
                <GridItem xs={10}>
                  <h6 className="text-grey">
                    Salesforceにデータを連携するため、
                    <br />
                    Evercall項目とマッピングルールを定義してください。
                  </h6>
                </GridItem>
                <GridItem xs={2} align="right">
                  <LoadingButton
                    color="secondary"
                    disabled={enableBtn()}
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    保存
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer direction="row">
                <GridItem xs={12} align="center">
                  <Box className="history-mapping-table">
                    <Table
                      isDisableOverflow
                      tableData={renderDataTable()}
                    />
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer direction="row">
                <GridItem xs={12} align="right">
                  <Button
                    justIcon
                    className="btn-history-setting"
                    onClick={addMapping}
                    disabled={disabledAddFilter(
                      sfData,
                      evercallData,
                      filteredData
                    )}
                  >
                    <Add />
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
