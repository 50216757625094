import { cloneDeep } from "lodash";

export function clearSFItem(data) {
  let result = cloneDeep(data);
  return result.map((item) => {
    item.salesforce_item = "";
    return item;
  });
}

export function changeMappingLogicSelect(data, index, key, val) {
  let result = cloneDeep(data);
  result[index][key] = val ? val.label : "";
  if (key === "ec_item") {
    result[index]["head_id"] = val && val.head_id ? val.head_id : null;
  }
  return result;
}

export function mappingLogicData(data, company_id) {
  let result = cloneDeep(data);

  const obj = {
    company_id: company_id,
    ec_item: "",
    salesforce_item: "",
  };
  result.push(obj);
  return result;
}

export function mappingLogicKington(data, company_id) {
  let result = cloneDeep(data);

  const obj = {
    company_id: company_id,
    ec_item: "",
    kintone_item: "",
    head_id: undefined,
  };

  result.push(obj);
  return result;
}

export function deleteMappingData(data, index) {
  let result = cloneDeep(data);
  result.splice(index, 1);
  return result;
}
