import React, { useEffect, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import { isEmpty } from "lodash";
import Moment from "moment";
import Datetime from "react-datetime";
// import { useDispatch, useSelector } from "react-redux";
// import { alertActions } from "../../actions/alert.actions";
import logoEc from "../../assets/img/logoEc.png";
import logoKt from "../../assets/img/logokintone.png";
import logoOth from "../../assets/img/logoOther.png";
import logoSf from "../../assets/img/logosf.png";
// import { //ShowSweetAlert,
//   redirectWithErrorCode } from "../../helpers/common";
import styles from "./Component/dashboardStyle";
import List from "./Component/List";
import Square from "./Component/Square";

import { useData } from "./hooks/useData";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const alert = useSelector((state) => state.alert);
  // const [isShowAlert, setIsShowAlert] = useState(false);

  const [params, setParams] = useState({
    start_date: Moment().subtract(6, "day").format("YYYY-MM-DD"),
    end_date: Moment().format("YYYY-MM-DD"),
    page: 1,
  });
  const [data, isShowBackdrop, isLoading] = useData(params);
  const {summary} = data;

  const validDate = (current) => {
    return current.isBefore(Moment());
  };

  useEffect(() => {
    document.title = "ダッシュボード";
  }, []);

  // useEffect(() => {
  //   setIsShowAlert(!isEmpty(alert) ? true : false);
  // }, [alert]);

  // Handle when change value
  function handleChangeDate(key, value) {
    if (key === "start_date" || key === "end_date") {
      if (Moment(value, "YYYY-MM-DD", true).isValid()) {
        value = Moment(value).format("YYYY-MM-DD");
      }
    }

    let start_date = key === "start_date" ? value : params.start_date;
    let end_date = key === "end_date" ? value : params.end_date;

    if (Moment(start_date).isAfter(end_date)) {
      start_date = value;
      end_date = value;
    }

    setParams((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  }

  // function handleAlertClick(code) {
  //   dispatch(alertActions.clear());
  //   if (redirectWithErrorCode(code)) {
  //     setIsShowAlert(false);
  //   }
  // }
  return (
    <>
      {/* {isShowAlert && (
        <ShowSweetAlert
          type={alert.type}
          title={alert.type === "danger" ? "エラー" : "完了"}
          message={alert.message.message}
          onClick={() => handleAlertClick(alert.message.error_code)}
        ></ShowSweetAlert>
      )} */}
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isShowBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer>
        <GridItem xs={12} sm={3} lg={2} className={classes.titleBox}>
          <span className={classes.titleBoxSpan}>日付</span>
        </GridItem>
        <GridItem xs={5} sm={3} lg={2}>
          <Box sx={{ width: "100%" }} pt={{ xs: 3, sm: 0 }}>
            <Datetime
              closeOnSelect
              isValidDate={validDate}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              value={params.start_date}
              onChange={(e) => handleChangeDate("start_date", e)}
              className="dateData"
            />
          </Box>
        </GridItem>
        <GridItem xs={1} className={classes.divideItem}>
          <Box sx={{ width: "100%" }} pt={{ xs: 3, sm: 0 }}>
            ~
          </Box>
        </GridItem>
        <GridItem xs={5} sm={3} lg={2}>
          <Box sx={{ width: "100%" }} pt={{ xs: 3, sm: 0 }}>
            <Datetime
              closeOnSelect
              isValidDate={validDate}
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              value={params.end_date}
              onChange={(e) => handleChangeDate("end_date", e)}
              className="dateData"
            />
          </Box>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.boxData}>
        <GridItem xs={12} sm={6} xl={3}>
          <Square
            title="Sales force連携"
            number={summary.sum_sf ?? 0}
            icon={logoSf}
            format="info"
          />
        </GridItem>
        <GridItem xs={12} sm={6} xl={3}>
          <Square
            title="キントーン連携"
            number={summary.sum_ktn ?? 0}
            icon={logoKt}
            format="warning"
          />
        </GridItem>
        <GridItem xs={12} sm={6} xl={3}>
          <Square
            title="その他連携"
            number={summary.sum_other ?? 0}
            icon={logoOth}
            format="success"
          />
        </GridItem>
        <GridItem xs={12} sm={6} xl={3}>
          <Square
            title="Ever Call連携"
            number={summary.sum_ec ?? 0}
            icon={logoEc}
            format="danger"
            marks="ec"
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <List {...data} isLoading={isLoading} />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            align="right"
          >
            {data.total_items > 0 && (
              <Pagination
                count={Math.ceil(data.total_items / 7)}
                page={params.page}
                className="pagination-rose"
                style={{ marginTop: "20px" }}
                onChange={(_e, page) =>
                  setParams((state) => ({
                    ...state,
                    page,
                  }))
                }
              />
            )}
          </Box>
        </GridItem>
      </GridContainer>
    </>
  );
}
