import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import InputLabel from "@material-ui/core/InputLabel";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import { LoadingButton } from "@mui/lab";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import { isEqual } from "lodash";
import {
  objToQueryString,
  cleanObject,
  regexTel,
} from "../../../helpers/common";
import { ERROR_MESSAGE } from "../../../helpers/errorMessage";

export default function SearchExtend(props) {
  const { searchExtend, onChangeParam, isLoading, onLoading } = props;
  const history = useHistory();
  const [params, setParams] = useState(searchExtend);
  const [collapse, setCollapse] = useState(searchExtend.collapse_search);
  const [loadingBtn, setLoadingBtn] = useState(true);
  const [inputErrors, setInputErrors] = useState({
    start_date: null,
    end_date: null,
    tel_for_find: null,
    isError: false,
  });

  useEffect(() => {
    setLoadingBtn(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setParams(searchExtend);
  }, [searchExtend]);

  function toggleSearchExtend() {
    setCollapse(!collapse);
  }

  // Handle when click button search extend
  function handleSearch() {
    if (inputErrors.isError) {
      return;
    }

    setLoadingBtn(true);

    const newParams = {
      ...params,
      collapse_search: collapse,
    };

    if (isEqual(cleanObject(newParams), searchExtend)) {
      return setLoadingBtn(false);
    }

    const queryParams = objToQueryString(newParams);
    history.push({
      pathname: window.location.pathname,
      search: queryParams,
    });
    onLoading(true);
    onChangeParam(queryParams);
  }

  // Handle when change value
  function handleChangeValue(e) {
    let { id, value } = e.target;
    if (id === "start_date" || id === "end_date") {
      const newDate = Moment(value, "YYYY-MM-DD", true);

      setInputErrors((state) => {
        return {
          ...state,
          [id]: !newDate.isValid() ? [ERROR_MESSAGE.DATE.INVALID] : null,
          isError: !newDate.isValid() ? true : false,
        };
      });

      const dateKey =
        id === "start_date" ? params.end_date : params.start_date;

      const oldDate = Moment(dateKey, "YYYY-MM-DD", true);

      if (
        (id === "start_date" && newDate > oldDate) ||
        (id === "end_date" && newDate < oldDate)
      ) {
        setInputErrors((state) => {
          return {
            ...state,
            [id]: [ERROR_MESSAGE.DATE.LESS_THEN_END],
            isError: true,
          };
        });
      }

      if (newDate.isValid()) {
        value = Moment(value).format("YYYY-MM-DD");
      }
    }

    if (id === "tel_for_find") {
      setInputErrors((state) => {
        return {
          ...state,
          [id]: !regexTel(value) ? [ERROR_MESSAGE.TEL.INVALID] : null,
          isError: !regexTel(value) ? true : false,
        };
      });
    }

    setParams((state) => ({ ...state, [id]: value }));
  }

  function getError(error) {
    return error?.length ? error[0] : "";
  }

  return (
    <Card sx={{ m: 0 }} className="search-extra">
      <CardBody>
        <GridContainer alignItems="center">
          <GridItem xs={6}>
            <h5 className="text-bold text-grey">検索条件</h5>
            <Collapse in={collapse}>
              <p className="text-grey">EverCallの顧客検索</p>
            </Collapse>
          </GridItem>
          <GridItem xs={6} align="right">
            <Button
              className="btn-search-extend"
              color="rose"
              onClick={toggleSearchExtend}
            >
              {collapse ? <Remove /> : <Add />} 詳細検索
            </Button>
          </GridItem>
          <Box sx={{ width: "100%" }}>
            <Collapse in={collapse} fullwidth="true">
              <Box sx={{ pt: 1 }}>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem xs={4}>
                      <Box sx={{ py: 1 }}>
                        <InputLabel className="text-grey">開始日</InputLabel>
                      </Box>
                      <CustomInput
                        error={inputErrors.start_date ? true : false}
                        helperText={getError(inputErrors.start_date)}
                        type="datetime"
                        formControlProps={{
                          fullWidth: true,
                          className: "input-search",
                        }}
                        onDateChange={(e) => handleChangeValue(e)}
                        inputProps={{
                          id: "start_date",
                          disabled: loadingBtn,
                          value: params.start_date ?? "",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4}>
                      <Box sx={{ py: 1 }}>
                        <InputLabel className="text-grey">終了日</InputLabel>
                      </Box>
                      <CustomInput
                        error={inputErrors.end_date ? true : false}
                        helperText={getError(inputErrors.end_date)}
                        type="datetime"
                        formControlProps={{
                          fullWidth: true,
                          className: "input-search",
                        }}
                        onDateChange={(e) => handleChangeValue(e)}
                        inputProps={{
                          id: "end_date",
                          disabled: loadingBtn,
                          value: params.end_date ?? "",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} className="filter-phone">
                      <InputLabel className="text-grey">電話番号</InputLabel>
                      <CustomInput
                        error={inputErrors.tel_for_find ? true : false}
                        helperText={getError(inputErrors.tel_for_find)}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          id: "tel_for_find",
                          disabled: loadingBtn,
                          onChange: (e) => handleChangeValue(e),
                          value: params.tel_for_find
                            ? params.tel_for_find.toString()
                            : "",
                          type: "text",
                          max: 100,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      align="center"
                      className="btn-loading-pink"
                    >
                      <LoadingButton
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<Search />}
                        variant="contained"
                        disabled={inputErrors.isError}
                        onClick={handleSearch}
                      >
                        検索
                      </LoadingButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </Box>
            </Collapse>
          </Box>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
