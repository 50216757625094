import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import Box from "@mui/material/Box";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import { FbLoading } from "../../../../components/Loading/FbLoading";
import { CONSTANT } from "../../../../constants/constant";
import { URL } from "../../../../helpers/url";
import { isNotEmpty } from "../../../../helpers/common";

export default function Taishou(props) {
  const { isProcess, onChange, company_id } = props;
  const [settingData, setSettingData] = useState({});

  useEffect(() => {
    setSettingData(props);
  }, [props]);

  async function handleChange(e) {
    const { id, checked } = e.target;
    onChange({ id, checked });
  }

  function disableBtn() {
    return !isNotEmpty(company_id) || isProcess;
  }

  function isChecked(n) {
    return (!n || n === 0) ? false : true;
  }

  return (
    <Card id="taishou">
      <CardHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="text-bold text-dark-grey">連携対象</h3>
          {isProcess && <FbLoading />}
        </Box>
        <p className="text-sm text-grey taishou-desc">
          連携したい対象データを選択してください。
          <br />
          項目マッピングの設定は 項目マッピング {"> "}
          <Link to={`${CONSTANT.PREFIX_ADMIN}${URL.SF_CUS_MAPPING}`}>
            顧客項目画面
          </Link>
          と
          <Link to={`${CONSTANT.PREFIX_ADMIN}${URL.SF_HIS_MAPPING}`}>
            通話履歴マッピング画面
          </Link>
          で設定してください。
          <br />
          定期実行の設定は {"> "}
          <Link to={`${CONSTANT.PREFIX_ADMIN}${URL.SF_BATCH_SETTING}`}>
            定期実行画面
          </Link>
          で詳細の実行タイミングを設定してください。
        </p>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="table-responsive">
          <Table
            tableHead={["データ", "ClickTo連携", "REALTIME", "定期実行"]}
            tableData={[
              [
                <>
                  <div className="text-light-bold text-dark-grey">
                    {" "}
                    顧客情報
                  </div>
                  <div className="text-grey">
                    EverCallから顧客情報を連携する
                  </div>
                </>,
                <div key="is_cus_connect">
                  <Switch
                    id="is_cus_connect"
                    color="primary"
                    checked={isChecked(settingData.is_cus_connect)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,
                <div key="is_cus_realtime_trans">
                  <Switch
                    id="is_cus_realtime_trans"
                    color="primary"
                    checked={isChecked(settingData.is_cus_realtime_trans)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,
                <div key="is_cus_batch_trans">
                  <Switch
                    id="is_cus_batch_trans"
                    color="primary"
                    checked={isChecked(settingData.is_cus_batch_trans)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,
              ],
              [
                <>
                  <div className="text-light-bold text-dark-grey">
                    通話履歴
                  </div>
                  <div className="text-grey">
                    EverCallの発着信の通話履歴情報を連携する
                  </div>
                </>,
                <div key="is_his_connect">
                  <Switch
                    id="is_his_connect"
                    color="primary"
                    checked={isChecked(settingData.is_his_connect)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,
                <div key="is_his_realtime_trans">
                  <Switch
                    id="is_his_realtime_trans"
                    color="primary"
                    checked={isChecked(settingData.is_his_realtime_trans)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,
                <div key="is_his_batch_trans">
                  <Switch
                    id="is_his_batch_trans"
                    color="primary"
                    checked={isChecked(settingData.is_his_batch_trans)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,
              ],
            ]}
            className="table mb-0"
          ></Table>
        </div>
      </CardBody>
    </Card>
  );
}
