import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import { useSelector } from "react-redux";

import routes from "routes.js";
import directRoutes from "directRoutes.js";
import { isEmpty } from "lodash";


import styles from "assets/jss/salto-react/layouts/adminStyle.js";
import { CONSTANT } from "../constants/constant";
import {
  ShowSweetAlert,
  redirectWithErrorCode,
} from "../helpers/common";
import { alertActions } from "../actions/alert.actions";


var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("assets/img/sidebar-2.jpg").default);
  const [color] = React.useState("blue");
  const [bgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [logo] = React.useState(require("assets/img/logo.svg").default);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const alert = useSelector((state) => state.alert);

  // const user = useSelector((state) => state.authentication.user);
  
  useEffect(() => {
    setIsShowAlert(!isEmpty(alert) ? true : false);
  }, [alert]);

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components

  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // If menu have collapse, show menu in collapse - Active code to show menu collapse
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === CONSTANT.PREFIX_ADMIN) {
        // if (prop.permission  != "all" && user.permissions.indexOf(prop.permission) < 0 ){
        //   return null;
        // }
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />);
      }
    });
  };
  const getDirectRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const duplicateTelImportECErrorMessage = alert.message?.error?.error_type === "duplicated" ? 
  <div style={{overflowY: "auto", maxHeight:200}}>
    <p>
      以下の電話番号は選択したリストで既に存在しています。
      {alert.message?.error.tels.map((el,index)=> <React.Fragment key={index}> <br/>{el}</React.Fragment>)}
    </p>
  </div> 
    : "";

  function handleAlertClick(code) {
    dispatch(alertActions.clear());
    setIsShowAlert(false);
    redirectWithErrorCode(code);
  }

  return (
    <div className={classes.wrapper}>
      {isShowAlert && (
        <ShowSweetAlert
          type={alert.type}
          title={alert.type === "danger" ? "エラー" : "完了"}
          message={alert.message?.error?.error_type ? duplicateTelImportECErrorMessage : alert.message.message}
          details={alert.message.details}
          onClick={() => handleAlertClick(alert.message.error_code)}
          duplicateTelList={alert.message.customers_tel_duplicate_list}
        ></ShowSweetAlert>
      )}
      <Sidebar
        routes={routes}
        // logoText={""}
        logo={logo}
        image={image}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          brandDetail={getActiveRoute(directRoutes)}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              {getDirectRoutes(directRoutes)}
            </Switch>
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}
