export const FILTER_TYPE = {
  num_type: [
    {
      key: "blank",
      label: "空白",
    },
    {
      key: "not_blank",
      label: "空白ではない",
    },
    {
      key: "contain",
      label: "次を含むテキスト",
    },
    {
      key: "equal",
      label: "完全一致するテキスト",
    },
    {
      key: ">",
      label: "次より大きい",
    },
    {
      key: "<",
      label: "次より小さい",
    },
    {
      key: "=",
      label: "次と等しい",
    },
    {
      key: ">=",
      label: "以上",
    },
    {
      key: "<=",
      label: "以下"
    },
    {
      key: "!=",
      label: "次と等しくない"
    },
    {
      key: "date",
      label: "日付"
    },
    {
      key: "before",
      label: "次より前の日付"
    },
    {
      key: "after",
      label: "次より後の日付"
    },
    {
      key: "start_with",
      label: "次で始まるテキスト"
    },
    {
      key: "end_with",
      label: "次で終わるテキスト"
    },
    {
      key: "not_contain",
      label: "次を含まないテキスト"
    },
    {
      key: "between",
      label: "次の間にある"
    },
    {
      key: "not_between",
      label: "次の間にない"
    }
  ],
  str_type: [
    {
      key: "blank",
      label: "空白",
    },
    {
      key: "not_blank",
      label: "空白ではない",
    },
    {
      key: "contain",
      label: "次を含むテキスト",
    },
    {
      key: "equal",
      label: "完全一致するテキスト",
    },
    {
      key: "str_gt",
      label: "次より大きい",
    },
    {
      key: "str_lt",
      label: "次より小さい",
    },
    {
      key: "=",
      label: "次と等しい",
    },
    {
      key: "str_gte",
      label: "以上",
    },
    {
      key: "str_lte",
      label: "以下"
    },
    {
      key: "!=",
      label: "次と等しくない"
    },
    {
      key: "date",
      label: "日付"
    },
    {
      key: "before",
      label: "次より前の日付"
    },
    {
      key: "after",
      label: "次より後の日付"
    },
    {
      key: "start_with",
      label: "次で始まるテキスト"
    },
    {
      key: "end_with",
      label: "次で終わるテキスト"
    },
    {
      key: "not_contain",
      label: "次を含まないテキスト"
    },
    {
      key: "between",
      label: "次の間にある"
    },
    {
      key: "not_between",
      label: "次の間にない"
    }
  ],
  date_type: [
    {
      key: "blank",
      label: "空白",
    },
    {
      key: "not_blank",
      label: "空白ではない",
    },
    {
      key: "contain",
      label: "次を含むテキスト",
    },
    {
      key: "!=",
      label: "次と等しくない"
    },
    {
      key: "date",
      label: "日付"
    },
    {
      key: "before",
      label: "次より前の日付"
    },
    {
      key: "after",
      label: "次より後の日付"
    },
    {
      key: "start_with",
      label: "次で始まるテキスト"
    },
    {
      key: "end_with",
      label: "次で終わるテキスト"
    },
    {
      key: "not_contain",
      label: "次を含まないテキスト"
    },
    {
      key: "between",
      label: "次の間にある"
    },
    {
      key: "not_between",
      label: "次の間にない"
    }
  ]
};

export const KINTONE_FILTER_TYPE = {
  str_type: [
    {
      key: "blank",
      label: "空白",
    },
    {
      key: "not_blank",
      label: "空白ではない",
    },
    {
      key: "equal",
      label: "完全一致するテキスト",
    },
    {
      key: "start_with",
      label: "次で始まるテキスト"
    },
    {
      key: "end_with",
      label: "次で終わるテキスト"
    },
    {
      key: "contain",
      label: "次を含むテキスト"
    },
    {
      key: "not_contain",
      label: "次を含まないテキスト"
    },
  ],
  num_type:
    [
      {
        key: "blank",
        label: "空白",
      },
      {
        key: "not_blank",
        label: "空白ではない",
      },
      {
        key: "equal",
        label: "完全一致するテキスト",
      },
      {
        key: ">",
        label: "次より大きい ",
      },
      {
        key: "<",
        label: "次より小さい",
      },
      {
        key: "=",
        label: "次と等しい",
      }, {
        key: ">=",
        label: "以上",
      },
      {
        key: "<=",
        label: "以下"
      },
      {
        key: "!=",
        label: "次と等しくない"
      },
      {
        key: "date",
        label: "日付"
      },
      {
        key: "before",
        label: "次より前の日付"
      },
      {
        key: "after",
        label: "次より後の日付"
      },
      {
        key: "start_with",
        label: "次で始まるテキスト"
      },
      {
        key: "end_with",
        label: "次で終わるテキスト"
      },
      {
        key: "contain",
        label: "次を含むテキスト"
      },
      {
        key: "not_contain",
        label: "次を含まないテキスト"
      },
      {
        key: "between",
        label: "次の間にある"
      },
      {
        key: "not_between",
        label: "次の間にない"
      }
    ],
  date_type: [
    {
      key: "blank",
      label: "空白",
    },
    {
      key: "not_blank",
      label: "空白ではない",
    },
    {
      key: "contain",
      label: "次を含むテキスト",
    },
    {
      key: "!=",
      label: "次と等しくない"
    },
    {
      key: "date",
      label: "日付"
    },
    {
      key: "before",
      label: "次より前の日付"
    },
    {
      key: "after",
      label: "次より後の日付"
    },
    {
      key: "start_with",
      label: "次で始まるテキスト"
    },
    {
      key: "end_with",
      label: "次で終わるテキスト"
    },
    {
      key: "not_contain",
      label: "次を含まないテキスト"
    },
    {
      key: "between",
      label: "次の間にある"
    },
    {
      key: "not_between",
      label: "次の間にない"
    }
  ]
};
