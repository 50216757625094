import { API_ZOHO } from "../helpers/apiURL";
import { fetchAsGet, fetchAsPost } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchCusMapping(dispatch) {
  const res = await fetchAsGet(API_ZOHO.CUS_MAPPING);
  const result = handleResData(res, dispatch);

  if (!result.isOk) {
    return { data: [], salesforce_object: null };
  }
  const { data, salesforce_object } = result.data;

  return { data, salesforce_object };

}

export async function fetchCusMappingInfo(dispatch) {
  const res = await fetchAsGet(API_ZOHO.CUS_MAPPING_INFO);
  return handleResData(res, dispatch);
}

export async function fetchUpdateCusMapping(params, dispatch) {
  const res = await fetchAsPost(API_ZOHO.CUS_MAPPING, params);
  return handleResData(res, dispatch, true);
}

export async function setCurrenModule(params, dispatch) {
  const res = await fetchAsPost(API_ZOHO.CURRENT_MAPPING, params);
  return handleResData(res, dispatch, true);
}

export async function fetchModules(dispatch) {
  const res = await fetchAsGet(API_ZOHO.MODULES);
  const result = handleResData(res, dispatch);

  if (!result.isOk) {
    return { data: [] };
  }
  const { zoho_modules, current_module, parent_module, unique_fields, lookup_contact, memo_contact, lookup_lead, memo_lead } = result.data;

  return { zoho_modules, current_module, parent_module, unique_fields, lookup_contact, memo_contact, lookup_lead, memo_lead };
}
