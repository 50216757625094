import Moment from "moment";
const queryString = require("query-string");

export function useSearchParam(search) {
  if (!search) {
    return {
      collapse_search: false,
      start_date: Moment().format("YYYY-MM-DD"),
      end_date: Moment().format("YYYY-MM-DD"),
      rows_per_page: 10,
      page: 1,
    };
  }

  const params = queryString.parse(search);
  const newParams = {
    ...params,
    page: params.page && +params.page,
    rows_per_page: params.rows_per_page ? +params.rows_per_page : 10,
    collapse_search:
      params.collapse_search && JSON.parse(params.collapse_search),
  };
  return newParams;
}
