import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import alertStyles from "assets/jss/salto-react/views/sweetAlertStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomInput from "components/CustomInput/CustomInput.js";
import DataTable from "components/DataTable/DataTable.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { isEmpty } from "lodash";
import Moment from "moment";
import { useDispatch } from "react-redux";

import styles from "views/Account/Components/accountStyle.js";
import plus from "../../assets/img/plus.png";
import remove from "../../assets/img/remove.png";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useValidate } from "../../hooks/useValidate";

import {
  deleteUser,
  getUsers,
  registerUser,
  updateUser,
} from "../../services/user.service";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertStyles);


function initUser(
  email = "",
  password = "",
  first_name = "",
  is_staff = true,
  uuid = "",
  is_active = true
) {
  return { email, password, first_name, is_staff, uuid, is_active };
}

export function Account() {
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dataUser, setDataUser] = useState([]);
  const [emailDisable, setEmailDisable] = useState(false);
  const [open, setOpen] = useState(true);
  const [isCollapse, setIsCollapse] = useState({
    management: true,
    salesforce: true,
    kintone: true,
    ever_api: true,
    zoho: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userUpdate, setUserUpdate] = useState(() => initUser());
  const [permissions, setPermissions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [validateMess, setValidateMess] = useState(null);
  const [popupConfirm, setPopupConfirm] = useState(null);
  const alertClasses = useAlertStyles();
  const [error, setError] = useState({ isError: false });
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [formValid, setFormValid] = useState(
    {
      "email": true,
      "password": true,
      "first_name": true,
    }
  );


  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData() {
    setIsLoading(true);
    const res = await getUsers(dispatch);
    if (res.isOk) {
      setDataUser(res.data.data);
      setPermissions(res.data.permissions);
      setGroups(res.data.groups);
    }
    setIsLoading(false);
    setOpen(false);
  }

  /**
   * Change switch in dataTable
   * @param {string} id
   * @param {string} value
   * @param {int} index
   * @returns {any}
   */
  async function handleChangeSwitch(user, active) {
    const currentUser = initUser(
      user.email,
      "",
      user.first_name,
      user.is_staff,
      user.uuid,
      !active
    );
    // set permission to user
    const permissionsUser = permissions.map((item) => {
      if (
        !isEmpty(user.user_permissions) &&
        user.user_permissions.indexOf(item.id) > -1
      ) {
        item.is_checked = true;
      } else {
        item.is_checked = false;
      }
      return item;
    });
    const data = { user: currentUser, permissions: permissionsUser };
    const res = await updateUser(data, dispatch);
    if (res.isOk) {
      fetchData();
    } else {
      return setError(getErrors(res.errors.message));
    }
  }

  function getErrors(data) {
    const error = Object.keys(data).reduce((obj, key) => {
      obj[key] = data[key][0];
      return obj;
    }, {});

    return { ...error, isError: true };
  }

  /**
   * Click button delete in row dataTable
   * @param {string} id
   * @param {int} index
   * @returns {any}
   */
  async function handleDelete(id) {
    const res = await deleteUser(id, dispatch);
    if (res.isOk) {
      fetchData();
    } else {
      return setError(getErrors(res.errors.message));
    }
  }

  /**
   * handle show popup confirm
   * @param {any} id
   * @returns {any}
   */
  function handPopupConfirm(id) {
    setPopupConfirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={"アカウントの削除"}
        onConfirm={() => {
          handleDelete(id);
          hideAlert();
        }}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
        confirmBtnText="はい"
        cancelBtnText="いいえ"
        showCancel
      >
        {"削除しますか？"}
      </SweetAlert>
    );
  }

  // Hide alert
  function hideAlert() {
    setPopupConfirm(null);
  }

  /**
   * click button edit set value popup
   * @param {string} id
   * @param {int} index
   * @returns {any}
   */
  function handleEdit(user) {
    setValidateMess(null);
    setError({})
    setEmailDisable(true);
    setFormValid({
      "email": false,
      "password": false,
      "first_name": false,
    });
    const currentUser = initUser(
      user.email,
      "",
      user.first_name,
      user.is_staff,
      user.uuid
    );
    setUserUpdate({
      ...userUpdate,
      ...currentUser,
    });
    // set permission to user
    const permissionsUser = permissions.map((item) => {
      if (
        !isEmpty(user.user_permissions) &&
        user.user_permissions.indexOf(item.id) > -1
      ) {
        item.is_checked = true;
      } else {
        item.is_checked = false;
      }
      return item;
    });
    setPermissions(permissionsUser);
    // set checked input check all
    setCheckedAll(false);
    if (user.user_permissions.length === permissions.length) {
      setCheckedAll(true);
    }
    setModal(true);
  }

  /**
   * Click button add user
   * @returns {any}
   */
  function handleAdd() {
    setFormValid({
      "email": true,
      "password": true,
      "first_name": true,
    });
    setError({})
    setValidateMess(null);
    setEmailDisable(false);
    const currentUser = initUser();
    setUserUpdate({
      ...userUpdate,
      ...currentUser,
    });
    setModal(true);
    handleCheckedAll(true);
  }

  /**
   * handle change input select in popup
   * @param {string} value
   * @param {string} key
   * @returns {any}
   */
  function handleChange(e) {
    const { name, value } = e.target
    const [isError, key, message] = useValidate(name, value);

    setError((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));
    setFormValid((state) => ({
      ...state,
      [key]: isError
    }));

    if (emailDisable) { // Edit mode, password is not required
      if (value == "") {
        setError((state) => ({
          ...state,
          'password': false
        }));
      }

    }
    if (key === "is_staff") {
      if (value === 1) {
        handleCheckedAll(true);
      } else {
        handleCheckedAll(false);
      }
    }

    setUserUpdate((state) => ({
      ...state,
      [key]: value,
    }));
  }

  //
  /**
   * handle change collapse group permission
   * @param {any} key
   * @param {any} value
   * @returns {any}
   */
  function handleChangeCollapse(key, value) {
    setIsCollapse({ ...isCollapse, [key]: value });
  }

  /**
   * handle checked permission
   * @param {any} id
   * @returns {any}
   */
  function handleChecked(id) {
    let numberChecked = 0;
    const checkedPermissions = permissions.map((item) => {
      if (item.id === id) {
        item.is_checked = !item.is_checked;
      }
      if (item.is_checked == true) {
        numberChecked += 1;
      }
      return item;
    });
    setCheckedAll(false);
    if (numberChecked === permissions.length) {
      setCheckedAll(true);
    }

    setPermissions(checkedPermissions);
  }

  /**
   * handle checked all
   * @param {any} checked
   * @returns {any}
   */
  function handleCheckedAll(checked) {
    setCheckedAll(checked);
    const checkedPermissions = permissions.map((item) => {
      item.is_checked = checked;
      return item;
    });
    setPermissions(checkedPermissions);
  }

  /**
   * Click button Save
   * @param {object} user
   * @returns {any}
   */
  async function handleSave() {
    const data = { user: userUpdate, permissions };
    setIsLoadingBtn(true);

    const res = userUpdate.uuid
      ? await updateUser(data, dispatch)
      : await registerUser(data, dispatch);
    setIsLoadingBtn(false);
    if (!res.isOk) {
      return setError(getErrors(res.errors.message));
    }
    setModal(false);
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }


  /**
   * custom column dataTable
   * @param {object} user
   * @param {int} index
   * @returns {any}
   */
  const rows = dataUser && dataUser.map((user, index) => {
    let setSwitch = (
      <Switch
        value={user.is_active}
        name="is_his_batch_trans"
        color="primary"
        checked={user.is_active}
        disabled={false}
        onChange={() => handleChangeSwitch(user, user.is_active)}
      ></Switch>
    );
    let updated;
    if (user.updated) {
      updated = Moment(user.updated).format("YYYY-MM-DD HH:mm:ss");
    }
    let role;
    if (user.is_staff == false) {
      // is supper user
      role = "オペレーター";
    } else {
      // staff user
      role = "管理者";
    }
    let actions = (
      // we've added some custom button actions
      <div className="actions-right">
        <Button justIcon color="success" onClick={() => handleEdit(user)}>
          <EditIcon />
        </Button>
        {/* use this button to remove the data row */}
        <Button
          justIcon
          onClick={() => handPopupConfirm(user.uuid)}
          color="danger"
        >
          <Delete />
        </Button>{" "}
      </div>
    );

    let row_index = index + 1;

    return {
      ...user,
      is_active: setSwitch,
      updated: updated,
      role: role,
      actions: actions,
      row_index,
    };
  });

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  /**
   * Add list group permission
   * @returns {any}
   */
  const listGroups = () => {
    return groups.map((group, index) => {
      if (!group.name) {
        return null;
      } else {
        let isCollapsed = true;
        let key = "salesforce";
        if (group.name === "データ管理") {
          isCollapsed = isCollapse.management;
          key = "management";
        } else if (group.name === "セールスフォース") {
          isCollapsed = isCollapse.salesforce;
          key = "salesforce";
        } else if (group.name === "キントーン") {
          isCollapsed = isCollapse.kintone;
          key = "kintone";
        } else if (group.name === "Zoho") {
          isCollapsed = isCollapse.zoho;
          key = "zoho";
        } else if (group.name === "Ever API") {
          isCollapsed = isCollapse.ever_api;
          key = "ever_api";
        }
        return (

          <GridItem xs={12} key={index}>
            <div
              className={classes.groupPermission}
              color="rose"
              onClick={() => handleChangeCollapse(key, !isCollapsed)}
            >
              <GridContainer>
                <GridItem xs={6}>{group.name}</GridItem>
                <GridItem xs={6}>
                  <img
                    src={isCollapsed ? remove : plus}
                    className={classes.withIcon}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <Collapse in={isCollapsed}>
              <Box sx={{ display: "block", flexDirection: "column", ml: 4 }}>
                {listPermission(group.name)}
              </Box>
            </Collapse>
          </GridItem>
        );
      }
    });
  };

  /**
   * Add list permission with group
   * @param {any} group_name
   * @returns {any}
   */
  const listPermission = (group_name) => {
    return permissions.map((permission, idx) => {
      if (permission.group_name !== group_name) {
        return null;
      } else {
        return (
          <FormControlLabel
            key={idx}
            className={classes.withCheckBox}
            label={permission.name}
            control={
              <Checkbox
                onClick={() => handleChecked(permission.id)}
                checked={permission.is_checked}
                value={permission.codename}
              />
            }
          />
        );
      }
    });
  };

  // function handleErrorClick(code) {
  //   dispatch(alertActions.clear());
  //   if (redirectWithErrorCode(code)) {
  //     setIsShowAlert(false);
  //   }
  // }

  return (
    <>
      {/* {isShowAlert && (
        <ShowSweetAlert
          type={alert.type}
          title={alert.type === "danger" ? "エラー" : "完了"}
          message={alert.message.message}
          onClick={() => handleErrorClick(alert.message.error_code)}
        ></ShowSweetAlert>
      )} */}
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer>
        {popupConfirm}
        <Dialog
          fullWidth
          maxWidth="lg"
          classes={{
            root: classes.center,
          }}
          open={modal}
          transition={Transition}
          keepMounted
          onClose={() => setModal(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          disableEnforceFocus
        >
          <DialogTitle disableTypography>
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.titleStyle}>
              {emailDisable ? "アカウント編集" : "アカウント登録"}
            </h4>
          </DialogTitle>
          <DialogContent>
            {validateMess && (
              <SnackbarContent message={validateMess} color="danger" />
            )}
            <p className={classes.supTitleStyle}>
              設定機能は管理者だけ利用できます。
            </p>
            {/* </Card> */}
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  success={error.email !== undefined && error.email !== ""}
                  error={isEmpty(error.email) ? false : true}
                  helperText={error.email}
                  labelText="メールアドレース"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    disabled: emailDisable,
                    required: true,
                    type: "text",
                    name: "email",
                    value: userUpdate.email,
                    onChange: (e) => {
                      handleChange(e);
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  labelText="パスワード"
                  success={error.password !== undefined && error.password !== ""}
                  error={isEmpty(error.password) ? false : true}
                  helperText={error.password}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={""}
                  inputProps={{
                    required: false,
                    type: "password",
                    name: "password",
                    value: userUpdate.password,
                    onChange: (e) => {
                      handleChange(e);
                    },
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  labelText="氏名"
                  success={error.first_name !== undefined && error.first_name !== ""}
                  error={isEmpty(error.first_name) ? false : true}
                  helperText={error.first_name}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    name: "first_name",

                    value: userUpdate.first_name || '',
                    onChange: (e) => {
                      handleChange(e);
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={6} className={classes.marginSelectStyle}>
                <FormControl fullWidth variant="standard">
                  <Select
                    name="is_staff"
                    fullWidth
                    value={
                      userUpdate.is_staff || userUpdate.is_staff === undefined
                        ? 1
                        : 0
                    }
                    onChange={(e) => handleChange(e)}
                  >
                    <MenuItem value={0}>オペレーター</MenuItem>
                    <MenuItem value={1}>管理者</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <p className={classes.titlePermission}>権限設定</p>
                <FormControlLabel
                  className={classes.withCheckBox}
                  label="全て選択"
                  control={
                    <Checkbox
                      checked={checkedAll}
                      onClick={() => handleCheckedAll(!checkedAll)}
                    />
                  }
                />
              </GridItem>
              {listGroups()}
            </GridContainer>
            <GridItem xs={12} className={classes.alignCenter}>
              <LoadingButton
                color="secondary"
                disabled={formValid.email || formValid.first_name || formValid.password}
                loading={isLoadingBtn}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={handleSave}
              >
                保存
              </LoadingButton>
            </GridItem>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          ></DialogActions>
        </Dialog>
        <GridItem xs={12}>
          <Button color="rose" onClick={() => handleAdd()}>
            アカウント追加
          </Button>
          <Card>
            <CardHeader color="primary" icon style={{ color: "black" }}>
              <h5 className={classes.titleTableStyle + " text-dark-grey"}>
                アカウント一覧
              </h5>
              <p className={classes.supTitleTableStyle + " text-grey"}>
                EBIのシステムを使用するアカウントです。
              </p>
            </CardHeader>
            <CardBody>
              <DataTable
                rows={rows}
                columns={[
                  {
                    key: "row_index",
                    label: "#",
                    sortable: false,
                    minWidth: 20,
                  },
                  {
                    key: "first_name",
                    label: "氏名",
                    minWidth: 200,
                    wordBreak: "break-all",
                  },
                  { key: "email", label: "メールアドレス", minWidth: 210 },
                  { key: "role", label: "権限", minWidth: 150 },
                  {
                    key: "is_active",
                    label: "ステータス",
                    minWidth: 150,
                    // sortable: false,
                  },
                  { key: "updated", label: "更新日", minWidth: 200 },
                  {
                    key: "updated_by",
                    label: "更新者",
                    minWidth: 150,
                    wordBreak: "break-all",
                  },
                  {
                    key: "actions",
                    label: "ACTION",
                    minWidth: 150,
                    sortable: false,
                    // defaultSortField="dateOfAction",
                  },
                ]}
                rowsPerPageOptions={[10, 20, 50, 100]}
                defaultRowsPerPage={10}
                // bInfo={false}
                pagination
                searchBar
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
