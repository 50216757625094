import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { cloneDeep, isEqual, isNil, isUndefined, remove } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR_MESSAGE } from "../../../messages/index";
import {
  deleteMappingData,
  changeMappingLogicSelect,
  mappingLogicData,
} from "../../../business/mappingLogic";
import {
  fetchCusMapping,
  fetchCusMappingInfo,
  fetchUpdateCusMapping,
} from "../../../services/api.kintone";

import {
  findObjByKey,
} from "../../../helpers/common";

export default function KTCusMapping() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [mappingData, setMappingData] = useState([]);
  const [kintoneData, setKintoneData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [evercallData, setEvercallData] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    ec_item: "",
    ec_name: "",
    ktn_item: "",
    ktn_name: "",
  });
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "キントーン顧客項目マッピング";
    async function fetchApi() {
      const res = await fetchCusMapping();
      setMappingData(res.data);
      setFilteredData(res.data);

      const resInfo = await fetchCusMappingInfo(dispatch);
      if (resInfo.isOk) {
        setEvercallData(resInfo.data.ec_customer_schema);
        setKintoneData(resInfo.data.kintone_customer_schema);
      }

      setIsLoading(false);
    }

    fetchApi();
  }, []);

  function handleChangeSelect(index, key, value) {
    // Update customer mapping data state
    setMappingData((state) =>
      changeMappingLogicSelect(state, index, key, value)
    );

    // Update filter data state
    setFilteredData((state) =>
      changeMappingLogicSelect(state, index, key, value)
    );
  }

  function addCustomerMapping() {
    // Add customer mapping data to data state
    setMappingData((state) =>
      mappingLogicData(state, user.company_id)
    );

    // add customer mapping data to filter data
    setFilteredData((state) => mappingLogicData(state, user.company_id));
  }

  function deleteCustomerMapping(index) {
    setMappingData((state) => deleteMappingData(state, index));
    setFilteredData((state) => deleteMappingData(state, index));
  }

  async function handleUpdate() {
    setIsLoadingBtn(true);
    await fetchUpdateCusMapping({ customer_mapping: mappingData }, dispatch);
    setIsLoadingBtn(false);
  }

  function headerTypeData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        EVERCALL
      </h6>,
      "",

      <h6 key="ktn_name" className="color-blue text-bold">
        キントーン
      </h6>,
      "",
    ];
  }

  function headerData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        項目名
      </h6>,
      <h6 key="ec_item" className="color-red text-bold">
        項目KEY
      </h6>,

      <h6 key="ktn_name" className="color-blue text-bold">
        項目名
      </h6>,
      <h6 key="ktn_item" className="color-blue text-bold">
        項目KEY
      </h6>,
      <h6 key="action"></h6>,
    ];
  }

  function searchData() {
    return ["ec_item", "ec_name", "ktn_item", "ktn_name"].map((item) => (
      <CustomInput
        key={item}
        formControlProps={{
          fullWidth: true,
          className: "input-search",
        }}
        inputProps={{
          onChange: (e) => {
            filterTables(item, e.target.value);
          },
          type: "text",
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    ));
  }

  function deleteSelectedItem(key, label) {
    const options = key === "ec_item" ? evercallData : kintoneData;
    let result = cloneDeep(options);
    const copyData = [...filteredData];

    for (let item of copyData) {
      remove(result, (e) => {
        const condition =
          e.label !== label && e.label === item[key];

        if (key === "ec_item") {
          const resHeadId = isNil(e.head_id) ? "" : e.head_id;
          const itemHeadId = isNil(item["head_id"]) ? "" : item["head_id"];
          return condition && resHeadId === itemHeadId;
        }

        return condition;
      });
    }

    return result;
  }

  function findSelectedItem(val, key = "ec_item") {
    const items = key === "ec_item" ? evercallData : kintoneData;
    const obj = findObjByKey(items, "label", val);
    if (isUndefined(obj)) {
      return null;
    }

    return obj;
  }

  function findSelectedEcItem(val) {
    const obj = evercallData.find((item) => {
      if (val.head_id) {
        return item.label === val.ec_item && item.head_id === val.head_id;
      } else {
        return item.label === val.ec_item;
      }
    });
    if (isUndefined(obj)) {
      return null;
    }
    return obj;
  }

  function renderDataTable(data) {
    let result = data.map((item, index) => {
      const everCall = evercallData.find((e) => {
        if (item.head_id) {
          return e.label === item.ec_item && e.head_id === item.head_id;
        } else {
          return e.label === item.ec_item;
        }
      });
      const kintone = kintoneData.find((e) => e.label === item.kintone_item);

      let temp = [
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedEcItem(item) || null}
          options={deleteSelectedItem("ec_item", item.ec_item)}
          groupBy={(option) => option.head_name}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return <TextField
              helperText={!item.ec_item ? ERROR_MESSAGE.REQUIRED : ""}
              variant="standard" {...params}
              error={!item.ec_item ? true : false}
            />;
          }}
          onChange={(_e, v) => handleChangeSelect(index, "ec_item", v)}
        />,
        !everCall ? "" : (everCall.head_name ? everCall.head_name + "_" : "") + everCall.label,
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedItem(item.kintone_item, "kintone_item")}
          options={deleteSelectedItem("kintone_item", item.kintone_item)}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return <TextField
              helperText={!item.kintone_item ? ERROR_MESSAGE.REQUIRED : ""}
              variant="standard" {...params}
              error={!item.kintone_item ? true : false} />;
          }}
          onChange={(_e, v) => handleChangeSelect(index, "kintone_item", v)}
        />,
        !kintone ? "" : kintone.label,
        <Button
          key={item.id}
          justIcon
          color="danger"
          className="btn-history-setting"
          onClick={() => deleteCustomerMapping(index)}
        >
          <Delete />
        </Button>,
      ];
      return temp;
    });

    // If filter condition is not empty, filter data
    if (filterCondition["ec_item"] !== "") {
      const lowerCaseData = filterCondition["ec_item"].toLowerCase();
      result = result.filter(
        (e) =>
          e[0]?.props?.value?.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ec_name"] !== "") {
      const lowerCaseData = filterCondition["ec_name"].toLowerCase();
      result = result.filter(
        (e) => e[1]?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ktn_item"] !== "") {
      const lowerCaseData = filterCondition["ktn_item"].toLowerCase();
      result = result.filter(
        (e) =>
          e[2]?.props?.value?.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ktn_name"] !== "") {
      const lowerCaseData = filterCondition["ktn_name"].toLowerCase();
      result = result.filter(
        (e) => e[3]?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // Add search row to table
    result.unshift(searchData());

    // Add header to table
    result.unshift(headerData());

    // Add header type to table
    result.unshift(headerTypeData());

    return result;
  }

  // Function handle when input search change data
  function filterTables(key, value) {
    setFilterCondition({ ...filterCondition, [key]: value });
  }

  // Function to disable button
  function disabledAddFilter(kintone, evercall, filterData) {
    let selectedEvercall = [];
    let selectedsaleforce = [];
    for (let item of filterData) {
      selectedEvercall.push(item.ec_item);
      selectedsaleforce.push(item.kintone_item);
    }

    // If data of filter data is equal evercall or kintone data
    if (
      selectedEvercall.length === evercall.length ||
      selectedsaleforce.length === kintone.length
    )
      return true;
    else return false;
  }

  function enableBtn() {
    if (mappingData.length === 0) {
      return true;
    }

    return mappingData.some(
      (el) => el.ec_item === undefined || el.ec_item === "" || el.ec_item === null || el.kintone_item === undefined || el.kintone_item === "" || el.kintone_item === null
    );
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      
      <GridContainer direction="row">
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h3 className="text-bold text-dark-grey">顧客項目マッピング</h3>
              <GridContainer direction="row">
                <GridItem xs={10}>
                  <h6 className="text-grey">
                    キントーンにデータを連携するため、
                    <br />
                    Evercall項目とマッピングルールを定義してください。
                  </h6>
                </GridItem>
                <GridItem xs={2} align="right">
                  <LoadingButton
                    color="secondary"
                    disabled={enableBtn()}
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    保存
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer direction="row">
                <GridItem xs={12} align="center">
                  <Box className="history-mapping-table">
                    <Table
                      isDisableOverflow
                      tableData={renderDataTable(filteredData)}
                    />
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer direction="row">
                <GridItem xs={12} align="right">
                  <Button
                    justIcon
                    className="btn-history-setting"
                    onClick={addCustomerMapping}
                    disabled={disabledAddFilter(
                      kintoneData,
                      evercallData,
                      filteredData
                    )}
                  >
                    <Add />
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
