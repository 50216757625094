import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import { cloneDeep, remove, isEqual, isUndefined } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { findObjByKey } from "../../../helpers/common";
import { deleteMappingData, mappingLogicData } from "../../../business/mappingLogic";
import { ERROR_MESSAGE } from "../../../messages/index";
import {
  fetchMapping,
  fetchMappingInfo,
  fetchUpdateMapping,
} from "../../../services/api.kintone";

export default function KTHisMapping() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);
  const [isLoading, setIsLoading] = useState(true);
  const [mappingData, setMappingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [kintoneData, setKintoneData] = useState([]);
  const [ecData, setEcData] = useState([]);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [options, setOptions] = useState({
    ec_item: [],
    kintone_item: []
  });

  const [filterCondition, setFilterCondition] = useState({
    ec_item: "",
    ec_name: "",
    ktn_item: "",
    ktn_name: "",
  });

  useEffect(() => {
    document.title = "キントーン通話履歴項目マッピング";
    fetchAllData();
  }, []);


  async function fetchAllData() {
    const resMapping = await fetchMapping();
    setMappingData(resMapping.data);
    setFilteredData(resMapping.data);

    const resInfo = await fetchMappingInfo(dispatch);

    if (resInfo.isOk) {
      setEcData(resInfo.data.ec_history_schema);
      setKintoneData(resInfo.data.kintone_history_schema);
      const ecOptions = resInfo.data.ec_history_schema;
      const ktOptions = resInfo.data.kintone_history_schema;
      setOptions((state) => ({
        ...state,
        ec_item: ecOptions,
        kintone_item: ktOptions
      }));
    }

    setIsLoading(false);
  }

  // // Function handle when select change value
  function handleChangeSelect(index, key, value) {
    setMappingData((prevState) =>
      changeValueInArrayObj(prevState, index, key, value)
    );

    setFilteredData((prevState) =>
      changeValueInArrayObj(prevState, index, key, value)
    );
  }

  function changeValueInArrayObj(array, index, key, value) {
    let result = cloneDeep(array);
    result[index][key] = value;
    return result;
  }

  // Function add history mapping
  function addHistoryMapping() {
    setMappingData((state) => mappingLogicData(state, user.company_id));
    setFilteredData((state) => mappingLogicData(state, user.company_id));
  }

  function deleteMapping(index) {
    setMappingData((state) => deleteMappingData(state, index));
    setFilteredData((state) => deleteMappingData(state, index));
  }

  async function handleUpdate() {
    setIsLoadingBtn(true);
    await fetchUpdateMapping({ history_mapping: mappingData }, dispatch);
    setIsLoadingBtn(false);
  }

  // Function generate row search for table
  function searchData() {
    return ["ec_item", "ec_name", "ktn_item", "ktn_name"].map((item) => (
      <CustomInput
        key={item}
        formControlProps={{
          fullWidth: true,
          className: "input-search",
        }}
        inputProps={{
          onChange: (e) => {
            filterTables(item, e.target.value);
          },
          type: "text",
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    ));
  }

  function removeSelectedItem(key, label) {
    let result = cloneDeep(options[key]);
    const copyData = [...filteredData];
    for (let item of copyData) {
      remove(result, (e) => e.label !== label && e.label === item[key]);
    }
    return result;
  }

  function headerTypeData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        EVERCALL
      </h6>,
      "",
      <h6 key="ktn_name" className="color-blue text-bold">
        キントーン
      </h6>,
      "",
      "",
    ];
  }

  function headerData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        項目名
      </h6>,
      <h6 key="ec_item" className="color-red text-bold">
        項目KEY
      </h6>,

      <h6 key="ktn_name" className="color-blue text-bold">
        項目名
      </h6>,
      <h6 key="ktn_item" className="color-blue text-bold">
        項目KEY
      </h6>,
      <h6 key="action"></h6>,
    ];
  }

  function findSelectedItem(val, key = "ec_item") {
    const items = options[key];
    const obj = findObjByKey(items, "label", val);
    if (isUndefined(obj)) {
      return null;
    }

    return obj;
  }
  function renderDataTable() {
    const cloneData = [...filteredData];
    let result = [];
    result = cloneData.map((item, index) => {
      const everCall = findObjByKey(ecData, "label", item.ec_item);
      const kintone = findObjByKey(kintoneData, "label", item.kintone_item);

      return [
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedItem(item.ec_item, "ec_item") || null}
          options={removeSelectedItem("ec_item", item.ec_item)}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return <TextField
              helperText={!item.ec_item ? ERROR_MESSAGE.REQUIRED : ""}
              variant="standard" {...params}
              error={!item.ec_item ? true : false}
            />;
          }}
          onChange={(_e, val) => handleChangeSelect(index, "ec_item", val ? val.label : "")}
        />,
        !everCall ? "" : everCall.label,
        <Autocomplete
          disablePortal
          key={item.id}
          value={!kintone ? null : (findSelectedItem(item.kintone_item, "kintone_item") || null)}
          options={removeSelectedItem("kintone_item", item.kintone_item)}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return <TextField
              helperText={!item.kintone_item ? ERROR_MESSAGE.REQUIRED : ""}
              variant="standard" {...params}
              error={!item.kintone_item ? true : false} />;
          }}
          onChange={(_e, val) => handleChangeSelect(index, "kintone_item", val ? val.label : "")}
        />,
        !kintone ? "" : kintone.label,
        <Button
          key={item.id}
          justIcon
          color="danger"
          className="btn-history-setting"
          onClick={() => deleteMapping(index)}
        >
          <Delete />
        </Button>,
      ];
    });

    // If filter condition is not empty, filter data
    if (filterCondition["ec_item"] !== "") {
      const lowerCaseData = filterCondition["ec_item"].toLowerCase();
      result = result.filter(
        (e) => e[0].props.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ec_name"] !== "") {
      const lowerCaseData = filterCondition["ec_name"].toLowerCase();
      result = result.filter(
        (e) => e[1].toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ktn_item"] !== "") {
      const lowerCaseData = filterCondition["ktn_item"].toLowerCase();
      result = result.filter(
        (e) => e[2].props.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ktn_name"] !== "") {
      const lowerCaseData = filterCondition["ktn_name"].toLowerCase();
      result = result.filter(
        (e) => e[3].toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }
    // Add search row to table
    result.unshift(searchData());

    // Add header to table
    result.unshift(headerData());

    // Add header type to table
    result.unshift(headerTypeData());

    return result;
  }

  // Function handle when input search change data
  function filterTables(key, value) {
    setFilterCondition({ ...filterCondition, [key]: value });
  }

  // Function to disable button
  function disabledAddFilter(kintone, evercall, filterData) {
    let selectedEvercall = [];
    let selectedsaleforce = [];
    for (let item of filterData) {
      selectedEvercall.push(item.ec_item);
      selectedsaleforce.push(item.kintone_item);
    }

    // If data of filter data is equal evercall or kintone data
    if (
      selectedEvercall.length === evercall.length ||
      selectedsaleforce.length === kintone.length
    )
      return true;
    else return false;
  }

  function enableBtn() {
    if (mappingData.length === 0) {
      return true;
    }

    return mappingData.some(
      (el) => el.ec_item === undefined || el.ec_item === "" || el.ec_item === null ||
        el.kintone_item === undefined || el.kintone_item === "" ||
        el.kintone_item === null
    );
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer direction="row">
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h3 className="text-bold text-dark-grey">
                通話履歴項目マッピング
              </h3>
              <GridContainer direction="row">
                <GridItem xs={10}>
                  <h6 className="text-grey">
                    キントーンにデータを連携するため、
                    <br />
                    Evercall項目とマッピングルールを定義してください。
                  </h6>
                </GridItem>
                <GridItem xs={2} align="right">
                  <LoadingButton
                    color="secondary"
                    disabled={enableBtn()}
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    保存
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer direction="row">
                <GridItem xs={12} align="center">
                  <Box className="history-mapping-table">
                    <Table
                      isDisableOverflow
                      tableData={renderDataTable()}
                    />
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer direction="row">
                <GridItem xs={12} align="right">
                  <Button
                    justIcon
                    className="btn-history-setting"
                    onClick={addHistoryMapping}
                    disabled={disabledAddFilter(
                      kintoneData,
                      ecData,
                      filteredData
                    )}
                  >
                    <Add />
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
