const API_URL = process.env.REACT_APP_API_URL;

export const API_HOME = {
  GET: API_URL + "/api/dashboard",
};

export const API_CUSTOMER = {
  GET: API_URL + "/api/new-customer/get",
  INFO: API_URL + "/api/new-customer/info",
  SF_CHECK_LIMIT: API_URL + "/api/new-customer/salesforce/check-limit",
  SF_UPDATE: API_URL + "/api/new-customer/salesforce/update",
  KT_CHECK_LIMIT: API_URL + "/api/new-customer/kintone/check-limit",
  KT_UPDATE: API_URL + "/api/new-customer/kintone/update",
  EXPORT_CSV: API_URL + "/api/new-customer/export",
  UPDATE_STATUS: API_URL + "/api/new-customer/update-status",
  EC_IMPORT: API_URL + "/api/new-customer/evercall/import",
  DETAIL: API_URL + "/api/new-customer/detail",
  UPDATE_DETAIL: API_URL + "/api/new-customer/update",
  UPDATE_MEMO: API_URL + "/api/new-customer/memo/update",
};

export const API_HISTORY = {
  GET: API_URL + "/api/histories/get",
  SF_CHECK_LIMIT: API_URL + "/api/histories/salesforce/check",
  SF_UPDATE: API_URL + "/api/histories/salesforce/update",
  KT_CHECK_LIMIT: API_URL + "/api/histories/kintone/check",
  KT_UPDATE: API_URL + "/api/histories/kintone/update",
  EXPORT_CSV: API_URL + "/api/histories/export",
  DETAIL: API_URL + "/api/histories/detail",
};

export const API_EC = {
  GET: API_URL + "/api/ec-setting",
  UPDATE: API_URL + "/api/ec-setting/result-condition",
  LIST: API_URL + '/api/evercall-list-list'
};

export const API_SALES_FORCE = {
  SETTING: API_URL + "/api/salesforce/setting",
  CUS_MAPPING: API_URL + "/api/salesforce/customer-mapping",
  CUS_MAPPING_INFO: API_URL + "/api/salesforce/customer-mapping-info",
  CUS_SCHEMA: API_URL + "/api/salesforce/customer-scheme",
  HIS_MAPPING: API_URL + "/api/salesforce/history-mapping",
  HIS_MAPPING_INFO: API_URL + "/api/salesforce/history-mapping-info",
  HIS_SCHEMA: API_URL + "/api/salesforce/history-scheme",
  BATCH_SETTING: API_URL + "/api/salesforce-batch-setting",
  BATCH_LOG: API_URL + "/api/batch-logs",
  BATCH_CAPTURE_LOGS: API_URL + "/api/capture-logs",
};

export const API_KINTONE = {
  SETTING: API_URL + "/api/kintone/setting",
  CUS_MAPPING: API_URL + "/api/kintone/customer-mapping",
  CUS_MAPPING_INFO: API_URL + "/api/kintone/customer-mapping-info",
  CUS_SCHEMA: API_URL + "/api/kintone/customer-scheme",

  HIS_MAPPING: API_URL + "/api/kintone/history-mapping",
  HIS_MAPPING_INFO: API_URL + "/api/kintone/history-mapping-info",
  HIS_SCHEMA: API_URL + "/api/kintone/history-scheme",
  BATCH_SETTING: API_URL + "/api/kintone-batch-setting",
};

export const API_ZOHO = {
  SETTING: API_URL + "/api/zoho/setting",
  CUS_MAPPING: API_URL + "/api/zoho/customer-mapping",
  CUS_MAPPING_INFO: API_URL + "/api/zoho/customer-mapping-info",
  CUS_SCHEMA: API_URL + "/api/zoho/customer-scheme",
  MODULES: API_URL + "/api/zoho/customer-mapping/modules",
  CURRENT_MAPPING: API_URL + "/api/zoho/customer-mapping/set-current",
};
