import { alertConstants } from "../constants";

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: "success",
        message: action.message,
      };
    case alertConstants.ERROR:{
      if(action.message.message?.error?.error_type){
        return {
          type: "danger",
          message: action.message.message,
          details: action.details,
        };  
      }
      return {
        type: "danger",
        message: action.message,
        details: action.details,
      };}
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
