import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import VibrationIcon from "@mui/icons-material/Vibration";
import CallIcon from "@mui/icons-material/Call";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiButton from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Spinner from "react-bootstrap/Spinner";
import { visuallyHidden } from "@mui/utils";
import { Pagination } from "@material-ui/lab";
import Check from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";
import PersonIcon from "@material-ui/icons/Person";
import CallHistoryResultLabel from "./CallHistoryResultLabel";
import styles from "assets/jss/salto-react/views/regularFormsStyle";
import { isEqual, find } from "lodash";
// import FileCallHistory from "./FileCallHistory";
import { AlertConfirm, AlertProcess } from "../../../helpers/AlertProcess";
import Moment from "moment";
import {
  cleanObject,
  objToQueryString,
  getOnlyNumber,
  dateFormat,
} from "../../../helpers/common";
import { CSVLink } from "react-csv";
import {
  fetchHistory,
  fetchCheckLimitSF,
  fetchBulkImportSF,
  fetchCheckLimitKT,
  fetchBulkImportKT,
  fetchExport,
} from "../../../services/api.history";

import { CONSTANT } from "../../../constants/constant";
import { URL } from "../../../helpers/url";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { Search } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
const useStyles = makeStyles(styles);

export default function HistoryListTable(props) {
  const { userInfo, searchExtend, onLoading, onChangeParam } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState("tel_time");
  const [orderType, setOrderType] = useState("desc");
  const logonUser = useSelector((state) => state.authentication.user);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [filter, setFilter] = useState({
    user_name: "",
    tel_filter: "",
    call_type: "",
    result: "",
  });
  const [currentParam, setCurrentParam] = useState({
    page: 1,
    rows_per_page: 10,
  });
  const [isLoadingUpdateKT, setIsLoadingUpdateKT] = useState(false);
  const [isLoadingUpdateSF, setIsLoadingUpdateSF] = useState(false);
  const [isSortable, setIsSortable] = useState(false);
  const [isPagination, setIsPagination] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selected, setSelected] = useState([]);
  const [notSelected, setNotSelected] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [exportCsv, setExportCsv] = useState({ body: [], header: [] });
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isProcessExport, setIsProcessExport] = useState(false);
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [alertProcess, setAlertProcess] = useState(null);

  const csvLink = React.createRef();
  const fileName = `local_history_${userInfo.company_id}_${Moment().format(
    "YYYYMMDDHHmmss"
  )}.csv`;

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isNotSelected = (name) => notSelected.indexOf(name) !== -1;

  const columnDatas = [
    { key: "user_name", label: "担当者", sortable: isSortable, minWidth: 160 },
    { key: "tel", label: "電話番号", sortable: isSortable, minWidth: 160 },
    { key: "call_type", label: "発着信", sortable: false, minWidth: 150 },
    { key: "connect_time", label: "通話秒数", sortable: isSortable, minWidth: 150 },
    { key: "tel_time", label: "通話日時", sortable: isSortable, minWidth: 150 },
    {
      key: "result",
      label: "結果パターン",
      sortable: true,
      minWidth: 180,
    },
    // { key: "record", label: "録音", sortable: false, minWidth: 90 },
    { key: "action", label: "ACTION", sortable: false, minWidth: 90 },
  ];

  useEffect(() => {
    let timeout;
    let current = true;
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    function fetchSearch() {
      if (current && !isFirstRun) {
        setCurrentParam(prevState => ({ ...prevState, page: 1 }))
        fetchApi();
      }
    }
    if (filter) {
      timeout = setTimeout(fetchSearch, 500);
    }
    return () => {
      current = false;
    };
  }, [filter]);

  useEffect(() => {
    let current = true;
    if (current && !isEqual(currentParam, searchExtend)) {
      setCurrentParam(searchExtend);
      fetchApi();
    }

    return () => {
      current = false;
    };
  }, [searchExtend, currentParam]);

  useEffect(() => {
    if (!isFirstRun) {
      fetchApi();
    }
  }, [orderBy, orderType]);

  useEffect(() => {
    if (isProcessExport) {
      setTimeout(() => {
        if (csvLink.current) {
          csvLink.current.link.click();
          setIsProcessExport(false);
        }
      });
      setAlertProcess(null);
    }
  }, [isProcessExport]);

  async function fetchApi() {
    const newTelFilter = filter.tel_filter.replace(/[- +]/g, "") ?? ""; // remove white space, -, +
    const params = cleanObject({
      ...searchExtend,
      ...filter,
      tel_filter: newTelFilter,
      order_by: orderBy,
      order_type: orderType,
      tel_for_find: getOnlyNumber(searchExtend.tel_for_find),
      result: filter.result ? filter.result : searchExtend.result
    });
    setIsProcess(true);
    const res = await fetchHistory(params, dispatch);
    if (res.isOk) {
      setRowData(res.data.data);
      setTotalItems(res.data.page_meta.total_items);
      setIsSortable(true);
      setIsPagination(true);
    }
    setIsFirstRun(false);
    onLoading(false);
    setIsProcess(false);
  }

  function getResultOptions() {
    if (searchExtend?.result) {
      const temp = searchExtend.result.split(",");
      const temp2 = temp.map(el => {
        return find(userInfo?.results, { result_pattern_id: Number.parseInt(el) })
      })
      return temp2
    }
    return userInfo?.results
  }

  function getCleanParams() {
    const newTelFilter = filter.tel_filter.replace(/[- +]/g, "") ?? "";
    return cleanObject({
      ...searchExtend,
      ...filter,
      tel_filter: newTelFilter,
      selected: selected.join(","),
      not_selected: notSelected.join(","),
      result: filter.result ? filter.result : searchExtend.result,
      is_check_all: +isCheckAll,
      rows_per_page: totalItems,
    });
  }

  function getCheckLimitParam() {
    return {
      total_item: totalItems,
      selected: selected.join(","),
      not_selected: notSelected.join(","),
      is_check_all: +isCheckAll,
    };
  }


  async function handleCheckLimitKT() {
    setIsLoadingUpdateKT(true);

    const res = await fetchCheckLimitKT(getCheckLimitParam(), dispatch);
    if (!res.isOk) {
      return setIsLoadingUpdateKT(false);
    }

    setAlertConfirm(
      <AlertConfirm
        data={res.data}
        showConfirm={true}
        type={1}
        onClick={(obj) => handleConfirm(obj)}
      />
    );
  }

  async function handleBulkImportKT() {
    setIsLoadingUpdateKT(true);
    await fetchBulkImportKT(getCleanParams(), dispatch);
    setAlertProcess(null);
    setIsLoadingUpdateKT(false);
  }

  async function handleCheckLimitSF() {
    setIsLoadingUpdateSF(true);
    const res = await fetchCheckLimitSF(getCheckLimitParam(), dispatch);
    if (!res.isOk) {
      return setIsLoadingUpdateSF(false);
    }

    setAlertConfirm(
      <AlertConfirm
        data={res.data}
        showConfirm={true}
        type={2}
        onClick={(obj) => handleConfirm(obj)}
      />
    );
  }

  async function handleBulkImportSF() {
    setIsLoadingUpdateSF(true);
    await fetchBulkImportSF(getCleanParams(), dispatch);
    setAlertProcess(null);
    setIsLoadingUpdateSF(false);
  }

  async function handleCheckLimitExport() {
    return checkOverRecord();
  }

  async function handleBulkExport() {
    setIsLoadingExport(true);
    const res = await fetchExport(getCleanParams(), dispatch);
    setAlertProcess(null);
    setIsLoadingExport(false);
    if (!res.isOk) {
      return setAlertProcess(null);
    }
    setExportCsv(res.data);
    setIsProcessExport(true);
  }

  function handleConfirm(obj) {
    const { isProcess, type } = obj;
    setAlertConfirm(null);
    if (!isProcess) {
      setIsLoadingUpdateSF(false);
      setIsLoadingUpdateKT(false);
      setIsLoadingExport(false);
      return;
    }

    if (type === 1) {
      setAlertProcess(1);
      handleBulkImportKT();
    }

    if (type === 2) {
      setAlertProcess(2);
      handleBulkImportSF();
    }

    if (type === 3) {
      handleBulkExport();
    }
  }

  function checkOverRecord(type = 3) {
    const updateItems = totalItems - notSelected.length;
    const check =
      (isCheckAll && updateItems > CONSTANT.LIMIT_EXPORT) ||
      selected.length > CONSTANT.LIMIT_EXPORT;

    return setAlertConfirm(
      <AlertConfirm
        data={{
          is_over: true,
          left: CONSTANT.LIMIT_EXPORT,
          update: isCheckAll ? updateItems : selected.length,
        }}
        showConfirm={!check}
        type={type}
        onClick={(obj) => handleConfirm(obj)}
      />
    );
  }

  /**
   * Because we have extend search so we need handle as below:
   * - Pass filter params throught props
   * - On props setParams state to trigger search again
   * @param {integer} page
   * @param {integer} rows_per_page
   */
  function handlePageAndPerPage(page = 1, rows_per_page = 10) {
    const params = objToQueryString({
      ...currentParam,
      page,
      rows_per_page,
    });

    history.push({
      pathname: window.location.pathname,
      search: params,
    });
    onChangeParam(params);
  }

  function handleCheckOnGrid(id) {
    const data = isCheckAll ? notSelected : selected;
    const selectedIdx = data.indexOf(id);
    let items = [...data];

    if (selectedIdx === -1) {
      items.push(id);
    }

    if (selectedIdx > -1) {
      items.splice(selectedIdx, 1);
    }

    isCheckAll ? setNotSelected(items) : setSelected(items);
    if (items.length === totalItems) {
      setIsCheckAll(isCheckAll ? false : true);
      setSelected([]);
      setNotSelected([]);
    }
  }

  function handleCheckAll(e) {
    setIsCheckAll(e.target.checked ? true : false);
    setSelected([]);
    setNotSelected([]);
  }

  function checkIsChecked() {
    return isCheckAll || selected.length || notSelected.length;
  }

  function handleSetSortStatus(p) {
    const isAsc = orderBy === p && orderType === "asc";
    setOrderType(isAsc ? "desc" : "asc");
    setOrderBy(p == "result_name" ? "result" : p);
  }

  function getDetailParams(row) {
    const { customer_no, tel_time } = row;

    return `?customer_no=${customer_no}&tel_time=${tel_time}`;
  }

  function getLink(row, key, isDate = false) {
    return (
      <Link
        to={{
          pathname: `${CONSTANT.PREFIX_ADMIN}${URL.HISTORY_DETAIL}`,
          search: getDetailParams(row),
        }}
      >
        {!isDate ? row[key] : dateFormat(row[key])}
      </Link>
    );
  }

  function renderRow() {
    if (isProcess) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columnDatas.length}>
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
          </TableCell>
        </TableRow>
      );
    }

    if (!rowData.length) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columnDatas.length + 1}>
            <div style={{ width: "100%", textAlign: "center" }}>
              該当データが存在しません。
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return rowData.map((row, idx) => {
      const isItemSelected = isSelected(row.unique_id);
      const isItemNotSelected = isNotSelected(row.unique_id);
      const labelId = `enhanced-table-checkbox-${idx}`;

      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={idx}
          selected={(isCheckAll || isItemSelected) && !isItemNotSelected}
        >
          <TableCell padding="checkbox" className="checkbox-circle-20">
            <Checkbox
              checkedIcon={<Check className={"checked-icon"} />}
              icon={<Check className={classes.uncheckedIcon} />}
              color="primary"
              onClick={() => handleCheckOnGrid(row.unique_id)}
              checked={(isCheckAll || isItemSelected) && !isItemNotSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
          <TableCell className="text-gray">
            <Link
              to={{
                pathname: `${CONSTANT.PREFIX_ADMIN}${URL.HISTORY_DETAIL}`,
                search: getDetailParams(row),
              }}
            >
              <Box sx={{ display: "flex" }} alignItems="center">
                <PersonIcon />
                {row.user_name}
              </Box>
            </Link>
          </TableCell>
          <TableCell className="text-gray">{getLink(row, "tel")}</TableCell>
          <TableCell className="text-gray">
            <Box sx={{ display: "flex" }} alignItems="center">
              <CallIcon fontSize="small" style={{ fill: "#1A73E8" }} />
              発信
            </Box>
          </TableCell>
          <TableCell className="text-gray">
            <Link
              to={{
                pathname: `${CONSTANT.PREFIX_ADMIN}${URL.HISTORY_DETAIL}`,
                search: getDetailParams(row),
              }}
            >
              <div>
                {Moment.utc(row.connect_time * 1000).format("HH:mm:ss")}
              </div>
            </Link>
          </TableCell>
          <TableCell className="text-gray">
            {getLink(row, "tel_time", true)}
          </TableCell>
          <TableCell className="text-gray">
            <CallHistoryResultLabel
              text={row.result_name}
              result={row.result}
            />
          </TableCell>
          {/*
          <TableCell className="text-gray">
              {row.media_source.length ? (
                <FileCallHistory source={row.media_source} />
              ) : (
                "Recording file not found"
              )}
            </TableCell>
          */}
          <TableCell className="text-gray">
            <Link
              to={{
                pathname: `${CONSTANT.PREFIX_ADMIN}${URL.HISTORY_DETAIL}`,
                search: getDetailParams(row),
              }}
            >
              <VibrationIcon fontSize="small" />
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  }

  function searchData() {
    return [
      "",
      "user_name",
      "tel_filter",
      // "call_type",
      "",
      "",
      "",
      "result",
      "",
      "",
    ].map((item, idx) => {
      if (item === "") {
        return <TableCell key={idx}></TableCell>;
      }


      if (item === "result") {
        return (
          <TableCell key={idx} className="result-name-filter">
            <Autocomplete
              key="result_name"
              className="custom-autocomplete"
              disablePortal
              options={getResultOptions() || []}
              getOptionLabel={(item) => item.name}
              onChange={(_e, newValue) => {
                setFilter((prevState) => ({
                  ...prevState,
                  [item]: newValue ? newValue.result_pattern_id : "",
                }));
              }}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </TableCell>
        );
      }

      return (
        <TableCell key={idx}>
          <CustomInput
            key={item}
            formControlProps={{
              fullWidth: true,
              className: "input-search",
            }}
            inputProps={{
              onChange: (e) => {
                setFilter((prevState) => ({
                  ...prevState,
                  [item]: e.target.value,
                }));
              },
              type: "text",
              value: filter[item] ? filter[item] : "",
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
      );
    });
  }

  return (
    <Card className="customer-list">
      {alertConfirm}
      {alertProcess && <AlertProcess type={alertProcess} />}
      <CSVLink
        ref={csvLink}
        data={exportCsv.body}
        headers={exportCsv.header}
        filename={fileName}
        target="_blank"
        asyncOnClick={true}
      />
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <h4 className="text-bold">通話履歴一覧</h4>
            <p className={classes.descHistoryPage}>
              デフォルトは本日分のEverCall通話履歴データが表示されています。（最大1万件）
              <br />
              もっと見たい場合は検索条件を指定してください。
            </p>
          </GridItem>
          <GridItem xs={6} align="right">
            <Button
              className="btn-kintone"
              color="rose"
              onClick={handleCheckLimitKT}
              disabled={!(logonUser.user_kintone.is_his_connect && checkIsChecked()) || isLoadingUpdateKT}
            >
              キントーン連携
              {isLoadingUpdateKT ? (
                <CircularProgress className="icon-loading" color="inherit" />
              ) : (
                ""
              )}
            </Button>
            <Button
              className="btn-salesforce"
              onClick={handleCheckLimitSF}
              disabled={!(logonUser.user_salesforce.is_his_connect && checkIsChecked()) || isLoadingUpdateSF}

            >
              SalesForce連携
              {isLoadingUpdateSF ? (
                <CircularProgress className="icon-loading" color="inherit" />
              ) : (
                ""
              )}
            </Button>
            <MuiButton
              className="btn-export"
              variant="outlined"
              color="secondary"
              onClick={handleCheckLimitExport}
              disabled={!checkIsChecked() || isLoadingExport}
            >
              Export
              {isLoadingExport ? (
                <CircularProgress className="icon-loading" color="inherit" />
              ) : (
                ""
              )}
            </MuiButton>
          </GridItem>
          <GridItem xs={12}>
            <Paper
              className="data-table"
              sx={{ width: "100%", overflow: "hidden" }}
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="customer-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        className="checkbox-circle-20"
                      >
                        <Checkbox
                          checkedIcon={<Check className={"checked-icon"} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          indeterminateIcon={
                            <Remove className={"indeterminate-icon"} />
                          }
                          color="primary"
                          indeterminate={
                            (selected.length > 0 &&
                              selected.length < totalItems &&
                              !isCheckAll) ||
                            (isCheckAll && notSelected.length > 0)
                          }
                          checked={
                            (rowData.length > 0 &&
                              selected.length === totalItems) ||
                            isCheckAll
                          }
                          onChange={handleCheckAll}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                      {columnDatas.map((column) => (
                        <TableCell
                          key={column.key}
                          padding="normal"
                          sortDirection={
                            orderBy == column.key ? orderType : false
                          }
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={orderBy === column.key}
                              direction={
                                orderBy === column.key ? orderType : "asc"
                              }
                              onClick={() => handleSetSortStatus(column.key)}
                            >
                              {column.label}
                              {orderBy === column.key ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {orderType === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>{searchData()}</TableRow>
                    {renderRow()}
                  </TableBody>
                </Table>
              </TableContainer>
              {isPagination ? (
                <GridContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <GridItem sx={6}>
                    {`Showing ${(currentParam.page - 1) * currentParam.rows_per_page + 1
                      } to
                  ${rowData.length +
                      (currentParam.page - 1) * currentParam.rows_per_page
                      } of ${totalItems}
                  entries`}
                  </GridItem>
                  <GridItem sx={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      align="right"
                    >
                      <TablePagination
                        className="hide-pagination"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={currentParam.rows_per_page}
                        page={0}
                        onPageChange={(_e, val) =>
                          handlePageAndPerPage(val, currentParam.rows_per_page)
                        }
                        onRowsPerPageChange={(e) =>
                          handlePageAndPerPage(1, e.target.value)
                        }
                      />
                      <Pagination
                        count={
                          Math.ceil(totalItems / currentParam.rows_per_page) > 0
                            ? Math.ceil(totalItems / currentParam.rows_per_page)
                            : 1
                        }
                        page={currentParam.page}
                        className="pagination-rose"
                        onChange={(_e, val) =>
                          handlePageAndPerPage(val, currentParam.rows_per_page)
                        }
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
              ) : (
                ""
              )}
            </Paper>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
