import Select from 'react-select';
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import styles from "views/Account/Components/accountStyle.js";
import { cloneDeep, isEqual, isNil, isUndefined, remove } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ERROR_MESSAGE } from "../../../messages/index";
import {
  deleteMappingData,
  changeMappingLogicSelect,
  mappingLogicData,
} from "../../../business/mappingLogic";
import {
  fetchCusMapping,
  fetchCusMappingInfo,
  fetchModules,
  fetchUpdateCusMapping,
  setCurrenModule,
} from "../../../services/api.zoho";

import { findObjByKey } from "../../../helpers/common";
const useStyles = makeStyles(styles);

export default function ZohoCusMapping() {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  const [mappingData, setMappingData] = useState([]);
  const [zohoData, setZohoData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [evercallData, setEvercallData] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    ec_item: "",
    ec_name: "",
    zoho_item: "",
    zoho_name: "",
  });
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [zohoModules, setZohoModules] = useState([]);
  const [zohoModulesDisplay, setZohoModulesDisplay] = useState([]);
  const [zohocurrentModules, setZohocurrentModules] = useState([]);
  const [zohoCustomMappedModule, setZohoCustomMappedModule] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [parentModule, setParentModule] = useState(false);
  const [isShowParent, setIsShowParent] = useState(false);
  const [lookupContact, setLookupContact] = useState(null);
  const [lookupLead, setLookupLead] = useState(null);
  const [memoValue, setMemoValue] = useState(null);
  const [memoItems, setMemoItems] = useState([]);
  const [isShowSettingMemo, setIsShowSettingMemo] = useState(false);
  const [labelMemo, setLabelMemo] = useState("");

  const dispatch = useDispatch();

  async function fetchApi() {
    const res = await fetchCusMapping();
    setMappingData(res.data);
    setFilteredData(res.data);
    const all = res.data
    .filter(item => !["phone_relationship", "unique_field"].includes(item.zoho_item))
    .map(item => ({ value: item.zoho_item, label: item.zoho_item}));

    const resInfo = await fetchCusMappingInfo(dispatch);
    if (resInfo.isOk) {
      setEvercallData(resInfo.data.ec_customer_schema);
      setZohoData(resInfo.data.zoho_customer_schema);
    }
    // fetchAllData();
    setZohocurrentModules(null);
    const resZoho = await fetchModules(dispatch);
    if (resZoho.zoho_modules !== 203) {
      setZohoModulesDisplay(resZoho.zoho_modules.display_module_name);
      setZohoModules(resZoho.zoho_modules.modules);

      setParentModule(resZoho.parent_module)
      if (resZoho.parent_module == true) {
        try {
          const unique_fields = all.filter(item => resZoho.unique_fields.includes(item.value))
          setSelectedOption(unique_fields)
        } catch (error) {
          setSelectedOption(null)
        }
        if (resZoho.lookup_contact) {
          const contact = resInfo.data.zoho_customer_schema.find(item => item.label === resZoho.lookup_contact);
          if (contact) {
            setLookupContact({label: contact['value'], value: contact['label']});
          }
        }
        if (resZoho.lookup_lead) {
          const lead = resInfo.data.zoho_customer_schema.find(item => item.label === resZoho.lookup_lead);
          if (lead) {
            setLookupLead({label: lead['value'], value: lead['label']}); 
          }
        }
       
      }
      
      setZohoCustomMappedModule(resZoho.zoho_modules.mapped_module);
      let index = resZoho.zoho_modules.modules.findIndex(
        (value) => value == resZoho.current_module
      );
      const cur_module_name = resZoho.zoho_modules.display_module_name[index]
      setZohocurrentModules(cur_module_name);
      const modules_not_chil = ["Home", "Contacts", "Leads", "見込み客", "連絡先"]
      if (!modules_not_chil.includes(cur_module_name)) {
        setIsShowParent(true)
      } else {
        setIsShowParent(false)
      }
      if (["Leads", "Contacts"].includes(cur_module_name)) {
        setIsShowSettingMemo(true)
        if (resInfo.isOk) {
          const item_module = resInfo.data.zoho_customer_schema ?? []
          const data_memo = item_module.filter(item => item.type === "string");
          const newItem = { label: "", value: "", type: "" };
          const AllItems = [newItem, ...data_memo];
          setMemoItems(AllItems);
          let select_value = ""
          if (cur_module_name === "Contacts") {
            select_value = resZoho.lookup_contact
            setLabelMemo("Duplicate memo field for 連絡先")
          } else {
            select_value = resZoho.lookup_lead
            setLabelMemo("Duplicate memo field for 見込み客")
          }
          const select_data = AllItems.find(item => item.label === select_value);
          if (select_data) {
            setMemoValue({label: select_data['value'], value: select_data['label']});
          }
        }
      } else {
        setIsShowSettingMemo(false)
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    document.title = "Zoho顧客項目マッピング";
    fetchApi();
  }, []);

  function isMappedModule(value) {
    return (
      zohoCustomMappedModule.findIndex((zohoModule) => zohoModule === value) >=
      0
    );
  }

  function handleChangeSelect(index, key, value) {
    // Update customer mapping data state
    setMappingData((state) =>
      changeMappingLogicSelect(state, index, key, value)
    );

    // Update filter data state
    setFilteredData((state) =>
      changeMappingLogicSelect(state, index, key, value)
    );
  }

  function addCustomerMapping() {
    // Add customer mapping data to data state
    setMappingData((state) => mappingLogicData(state, user.company_id));

    // add customer mapping data to filter data
    setFilteredData((state) => mappingLogicData(state, user.company_id));
  }

  function deleteCustomerMapping(index) {
    setMappingData((state) => deleteMappingData(state, index));
    setFilteredData((state) => deleteMappingData(state, index));
  }

  const getLookupValue = () => {
    // Default lookup values
    let lookup_contact = lookupContact ? lookupContact.value : "";
    let lookup_lead = lookupLead ? lookupLead.value : "";

    // Update values based on current module
    if (zohocurrentModules === "Leads") {
        lookup_lead = memoValue ? memoValue.value : "";
    } else if (zohocurrentModules === "Contacts") {
        lookup_contact = memoValue ? memoValue.value : "";
    }

    return { lookup_contact, lookup_lead };
  }

  async function handleUpdate() {
    setIsLoadingBtn(true);
    let index = zohoModulesDisplay.findIndex(
      (value) => value == zohocurrentModules
    );
    const uniqueFields = selectedOption?.map(item => item.value)
    const { lookup_contact, lookup_lead } = getLookupValue()

    await fetchUpdateCusMapping(
      { 
        customer_mapping: mappingData,
        module: zohoModules[index],
        parent_module: parentModule,
        unique_fields: uniqueFields,
        lookup_contact: lookup_contact,
        lookup_lead: lookup_lead,
      },
      dispatch
    );
    setIsLoadingBtn(false);
    if (mappingData.length > 0) {
      const newZohoMappedData = [
        ...zohoCustomMappedModule,
        zohoModulesDisplay[index],
      ];
      setZohoCustomMappedModule(newZohoMappedData);
    } else {
      const newZohoMappedData = zohoCustomMappedModule.filter(
        (module) => module != zohoModulesDisplay[index]
      );
      setZohoCustomMappedModule(newZohoMappedData);
    }
  }

  async function updateModule() {
    let index = zohoModulesDisplay.findIndex(
      (value) => value == zohocurrentModules
    );
    const uniqueFields = selectedOption?.map(item => item.value)
    const { lookup_contact, lookup_lead } = getLookupValue()
    await fetchUpdateCusMapping(
      { 
        customer_mapping: mappingData,
        module: zohoModules[index],
        parent_module: parentModule,
        unique_fields: uniqueFields,
        lookup_contact: lookup_contact,
        lookup_lead: lookup_lead,
      },
      dispatch
    );
  }

  async function handleChangeModule(newValue) {
    let index = zohoModulesDisplay.findIndex((value) => value == newValue);
    await setCurrenModule(
      { module: zohoModules[index] },
      dispatch
    );
    // reset data
    setParentModule(false);
    setIsShowParent(false);
    setLookupContact(null);
    setLookupLead(null);
    setIsShowSettingMemo(false);
    setMemoValue(null);
    setMemoItems([]);
    await fetchApi();
    // window.location.reload();
  }

  function headerTypeData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        EVERCALL
      </h6>,
      "",
      <GridContainer
        key="zoho_name"
        direction="row"
        justifyContent="space-between"
      >
        <h6 key="zoho_name" className="color-blue text-bold">
          Zoho
        </h6>
        ,
        <Box sx={{ mr: 2 }}>
          <h6 key="zoho_name" className="color-blue text-bold">
            モジュール
          </h6>
        </Box>
      </GridContainer>,
      <Autocomplete
        key="zoho_obj"
        disablePortal
        value={zohocurrentModules || null}
        options={zohoModulesDisplay}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {option}
                {isMappedModule(option) ? (
                  <CheckCircleIcon fontSize="small" color="success" />
                ) : null}
              </Box>
            </li>
          );
        }}
        renderInput={(params) => <TextField variant="standard" {...params} />}
        onChange={async (event, newValue) =>  {
          await updateModule();
          await handleChangeModule(newValue);
        }}
      />,
      "",
    ];
  }

  function headerData() {
    return [
      <h6 key="ec_name" className="color-red text-bold">
        項目名
      </h6>,
      <h6 key="ec_item" className="color-red text-bold">
        項目KEY
      </h6>,

      <h6 key="zoho_name" className="color-blue text-bold">
        項目名
      </h6>,
      <h6 key="zoho_item" className="color-blue text-bold">
        項目KEY
      </h6>,
      <h6 key="action"></h6>,
    ];
  }

  function searchData() {
    return ["ec_item", "ec_name", "zoho_item", "zoho_name"].map((item) => (
      <CustomInput
        key={item}
        formControlProps={{
          fullWidth: true,
          className: "input-search",
        }}
        inputProps={{
          onChange: (e) => {
            filterTables(item, e.target.value);
          },
          type: "text",
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    ));
  }

  function deleteSelectedItem(key, label) {
    const options = key === "ec_item" ? evercallData : zohoData;
    let result = cloneDeep(options);
    const copyData = [...filteredData];

    for (let item of copyData) {
      remove(result, (e) => {
        const condition = e.label !== label && e.label === item[key];

        if (key === "ec_item") {
          const resHeadId = isNil(e.head_id) ? "" : e.head_id;
          const itemHeadId = isNil(item["head_id"]) ? "" : item["head_id"];
          return condition && resHeadId === itemHeadId;
        } else {
          if (e.label === "phone_relationship" || e.label === "unique_field") {
            return true;
          }
        }

        return condition;
      });
    }

    return result;
  }

  function selectedItemZohoRelationship() {
    const options = mappingData
      .filter(item => !["phone_relationship_contacts", "phone_relationship_leads"].includes(item.zoho_item))
      .map(item => ({ value: item.zoho_item, label: item.zoho_item}));

    return options;
  }

  function selectedItemLookup() {
    const options = zohoData
      .filter(item => !["phone_relationship", "unique_field"].includes(item.zoho_item))
      .map(item => ({ value: item.label, label: item.value}));
    
    return options
  }

  function findSelectedItem(val, key = "ec_item") {
    const items = key === "ec_item" ? evercallData : zohoData;
    const obj = findObjByKey(items, "label", val);
    if (isUndefined(obj)) {
      return null;
    }

    return obj;
  }

  function findSelectedEcItem(val) {
    const obj = evercallData.find((item) => {
      if (val.head_id) {
        return item.label === val.ec_item && item.head_id === val.head_id;
      } else {
        return item.label === val.ec_item;
      }
    });
    if (isUndefined(obj)) {
      return null;
    }
    return obj;
  }

  function renderDataTable(data) {
    let result = data.map((item, index) => {
      const everCall = evercallData.find((e) => {
        if (item.head_id) {
          return e.label === item.ec_item && e.head_id === item.head_id;
        } else {
          return e.label === item.ec_item;
        }
      });
      const zoho = zohoData.find((e) => e.label === item.zoho_item);

      let temp = [
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedEcItem(item) || null}
          options={deleteSelectedItem("ec_item", item.ec_item)}
          groupBy={(option) => option.head_name}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return (
              <TextField
                helperText={!item.ec_item ? ERROR_MESSAGE.REQUIRED : ""}
                variant="standard"
                {...params}
                error={!item.ec_item ? true : false}
              />
            );
          }}
          onChange={(_e, v) => handleChangeSelect(index, "ec_item", v)}
        />,
        !everCall
          ? ""
          : (everCall.head_name ? everCall.head_name + "_" : "") +
            everCall.label,
        <Autocomplete
          disablePortal
          key={item.id}
          value={findSelectedItem(item.zoho_item, "zoho_item")}
          options={deleteSelectedItem("zoho_item", item.zoho_item)}
          isOptionEqualToValue={(option, value) => isEqual(option, value)}
          getOptionLabel={(option) => option.value || ""}
          renderInput={(params) => {
            return (
              <TextField
                helperText={!item.zoho_item ? ERROR_MESSAGE.REQUIRED : ""}
                variant="standard"
                {...params}
                error={!item.zoho_item ? true : false}
              />
            );
          }}
          onChange={(_e, v) => handleChangeSelect(index, "zoho_item", v)}
        />,
        !zoho ? "" : zoho.label,
        <Button
          key={item.id}
          justIcon
          color="danger"
          className="btn-history-setting"
          onClick={() => deleteCustomerMapping(index)}
        >
          <Delete />
        </Button>,
      ];
      return temp;
    });

    // If filter condition is not empty, filter data
    if (filterCondition["ec_item"] !== "") {
      const lowerCaseData = filterCondition["ec_item"].toLowerCase();
      result = result.filter(
        (e) =>
          e[0]?.props?.value?.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["ec_name"] !== "") {
      const lowerCaseData = filterCondition["ec_name"].toLowerCase();
      result = result.filter(
        (e) => e[1]?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["zoho_item"] !== "") {
      const lowerCaseData = filterCondition["zoho_item"].toLowerCase();
      result = result.filter(
        (e) =>
          e[2]?.props?.value?.value?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // If filter condition is not empty, filter data
    if (filterCondition["zoho_name"] !== "") {
      const lowerCaseData = filterCondition["zoho_name"].toLowerCase();
      result = result.filter(
        (e) => e[3]?.toLowerCase().indexOf(lowerCaseData) >= 0
      );
    }

    // Add search row to table
    result.unshift(searchData());

    // Add header to table
    result.unshift(headerData());

    // Add header type to table
    result.unshift(headerTypeData());

    return result;
  }

  // Function handle when input search change data
  function filterTables(key, value) {
    setFilterCondition({ ...filterCondition, [key]: value });
  }

  // Function to disable button
  function disabledAddFilter(zoho, evercall, filterData) {
    let selectedEvercall = [];
    let selectedsaleforce = [];
    for (let item of filterData) {
      selectedEvercall.push(item.ec_item);
      selectedsaleforce.push(item.zoho_item);
    }

    // If data of filter data is equal evercall or zoho data
    if (
      selectedEvercall.length === evercall.length ||
      selectedsaleforce.length === zoho.length
    )
      return true;
    else return false;
  }

  function enableBtn() {
    // if (mappingData.length === 0) {
    //   return true;
    // }
    return mappingData.some(
      (el) =>
        el.ec_item === undefined ||
        el.ec_item === "" ||
        el.ec_item === null ||
        el.zoho_item === undefined ||
        el.zoho_item === "" ||
        el.zoho_item === null
    );
  }

  /**
   * handle set parentModule
   * @param {any} checked
   * @returns {any}
   */
  async function handleCheckedParentModule() {
    setParentModule(!parentModule)
   
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>

      <GridContainer direction="row">
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h3 className="text-bold text-dark-grey">顧客項目マッピング</h3>
              <GridContainer direction="row">
                <GridItem xs={10}>
                  <h6 className="text-grey">
                    Zohoにデータを連携するため、
                    <br />
                    Evercall項目とマッピングルールを定義してください。
                  </h6>
                </GridItem>
                <GridItem xs={2} align="right">
                  <LoadingButton
                    color="secondary"
                    disabled={enableBtn()}
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    保存
                  </LoadingButton>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer direction="row">
                <GridItem xs={12} align="center">
                  <Box className="history-mapping-table">
                    <Table
                      isDisableOverflow
                      tableData={renderDataTable(filteredData)}
                    />
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer direction="row">
                <GridItem xs={12} align="right">
                  <Button
                    justIcon
                    className="btn-history-setting"
                    onClick={addCustomerMapping}
                    disabled={disabledAddFilter(
                      zohoData,
                      evercallData,
                      filteredData
                    )}
                  >
                    <Add />
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {isShowSettingMemo && (
            <Card>
              <CardBody>
                <GridContainer direction="row">
                  <GridItem xs={3} align="left">
                    <Box sx={{ mr: 2 }}>
                      <h6 key="memo_duplicate" className="color-blue text-bold">
                        {labelMemo}
                      </h6>
                    </Box>
                    <Select
                      value={memoValue}
                      onChange={setMemoValue}
                      options={memoItems}
                      className="basic-select"
                      classNamePrefix="select"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          )}
          {isShowParent && (
            <Card>
              <CardHeader>
                <h3 className="text-bold text-dark-grey">連携モジュール設定</h3>
                <GridContainer direction="row">
                  <GridItem xs={6}>
                      <Box sx={{ mr: 2 }}>
                        <h6 key="zoho_name" className="color-blue text-bold">
                          親モジュール
                        </h6>
                      </Box>
                      <FormControlLabel
                    className={classes.withCheckBox}
                    label="連絡先、見込み客"
                    control={
                      <Checkbox
                        checked={parentModule}
                        onClick={() => handleCheckedParentModule()}
                      />
                    }
                    style={{
                      fontSize: '14px',
                      width: '100%',
                    }}
                  />

                    </GridItem>
                    <GridItem xs={6} align="left">
                      <Box sx={{ mr: 2 }}>
                        <h6 key="zoho_name" className="color-blue text-bold">
                          分類条件項目（複数選択）
                        </h6>
                      </Box>
                      <Select
                        value={selectedOption}
                        isMulti
                        onChange={setSelectedOption}
                        options={selectedItemZohoRelationship()}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </GridItem>
                </GridContainer>
                {parentModule && (
                  <GridContainer direction="row">
                    <GridItem xs={3} align="left">
                        <Box sx={{ mr: 2 }}>
                          <h6 key="lookup_contacts" className="color-blue text-bold">
                            連絡先
                          </h6>
                        </Box>
                        <Select
                          value={lookupContact}
                          onChange={setLookupContact}
                          options={selectedItemLookup()}
                          className="basic-select"
                          classNamePrefix="select"
                        />
                    </GridItem>
                    <GridItem xs={3} align="left">
                      <Box sx={{ mr: 2 }}>
                        <h6 key="lookup_leads" className="color-blue text-bold">
                          見込み客
                        </h6>
                      </Box>
                      <Select
                        value={lookupLead}
                        onChange={setLookupLead}
                        options={selectedItemLookup()}
                        className="basic-select"
                        classNamePrefix="select"
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </CardHeader>
            <CardBody>
            </CardBody>
          </Card>
        )}
        </GridItem>
      </GridContainer>
    </>
  );
}
