import { API_ZOHO } from "../helpers/apiURL";
import { fetchAsPost, fetchAsGet, fetchAsPut } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchSetting(dispatch) {
  const res = await fetchAsGet(API_ZOHO.SETTING);
  return handleResData(res, dispatch);
}

export async function fetchUpdateSetting(body, dispatch) {
  const res = await fetchAsPost(API_ZOHO.SETTING, body);
  return handleResData(res, dispatch, true);
}

export async function fetchUpdateSwitch(body, dispatch) {
  const res = await fetchAsPut(API_ZOHO.SETTING, body);
  return handleResData(res, dispatch);
}
