import { useEffect, useState } from "react";

export function useInt(data) {
  const [settingData, setSettingData] = useState({});

  useEffect(() => {
    setSettingData(toInt(data));
  }, [data]);

  return [settingData, setSettingData];
}

export function toInt(data) {
  if (!data) {
    return {};
  }

  return Object.keys(allows(data)).reduce((obj, key) => {
    obj[key] = data[key] * 1;
    return obj;
  }, {});

}

export function allows(data) {
  const {
    is_realtime_trans,
  } = data;

  return {
    is_realtime_trans,
  };
}
