import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import Card from "components/Card/Card";
import Box from "@mui/material/Box";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Table from "components/Table/Table";
import { FbLoading } from "../../../../components/Loading/FbLoading";
import { CONSTANT } from "../../../../constants/constant";
import { URL } from "../../../../helpers/url";
import { isNotEmpty } from "../../../../helpers/common";

export default function Taishou(props) {
  const { isProcess, onChange, company_id } = props;
  const [settingData, setSettingData] = useState({});

  useEffect(() => {
    setSettingData(props);
  }, [props]);

  async function handleChange(e) {
    const { id, checked } = e.target;
    onChange({ id, checked });
  }

  function disableBtn() {
    return !isNotEmpty(company_id) || isProcess;
  }

  function isChecked(n) {
    return !n || n === 0 ? false : true;
  }

  return (
    <Card id="kt-taishou">
      <CardHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="text-bold text-dark-grey">連携対象</h3>
          {isProcess && <FbLoading />}
        </Box>
        <p className="text-sm text-grey taishou-desc">
          連携したい対象データを選択してください。
          <br />
          項目マッピングの設定は 項目マッピング {"> "}
          <Link to={`${CONSTANT.PREFIX_ADMIN}${URL.ZOHO_MAPPING}`}>
            顧客項目画面
          </Link>
          で設定してください。
          <br />
        </p>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="table-responsive">
          <Table
            tableHead={["データ", "REALTIME"]}
            tableData={[
              [
                <>
                  <div className="text-light-bold text-dark-grey">
                    {" "}
                    顧客情報
                  </div>
                  <div className="text-grey">
                    EverCallから顧客情報を連携する
                  </div>
                </>,
                <div key="is_realtime_trans">
                  <Switch
                    id="is_realtime_trans"
                    color="primary"
                    checked={isChecked(settingData.is_realtime_trans)}
                    disabled={disableBtn()}
                    onChange={handleChange}
                  ></Switch>
                </div>,

              ],
            ]}
            className="table mb-0"
          ></Table>
        </div>
      </CardBody>
    </Card>
  );
}
