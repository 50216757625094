import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Basic from "./Components/Basic";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput";
import LeftMenu from "./Components/Menu";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Taishou from "./Components/Taishou";
import { isEmptyObj, getErrors } from "../../../helpers/common";
import { useData } from "./hooks/useData";
import { toInt } from "./hooks/useComon";
import { useValidate } from "../../../hooks/useValidate";
import { fetchUpdateSetting, fetchUpdateSwitch } from "../../../services/api.kintoneSetting";
import { userActions } from "../../../actions/user.actions";

export default function index() {
  const dispatch = useDispatch();
  const [isOpen, data, setData] = useData();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [error, setError] = useState({});
  const [isProcess, setIsProcess] = useState(false);


  async function handleUpdate() {
    setIsLoadingBtn(true);
    const res = await fetchUpdateSetting(data, dispatch);
    setIsLoadingBtn(false);
    if (!res.isOk) {
      return setError(getErrors(res.errors.message));
    }
    setData(res.data.data);
  }

  function handleBindValue(e) {
    const { id, value } = e.target;
    const [isError, key, message] = useValidate(id, value);

    setError((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));

    setData((state) => ({ ...state, [key]: value }));
  }

  function enableBtn() {
    const { domain, cus_token, cus_app_id, his_token, his_app_id } = data;
    const isEmptyVal = isEmptyObj({ domain, cus_token, cus_app_id, his_token, his_app_id });

    return !(!error.isError && !isEmptyVal);
  }

  async function handleUpdateSwitch(obj) {
    const { id, checked } = obj;
    const newData = { ...data, [id]: checked };
    setData(newData);

    setIsProcess(true);
    const res = await fetchUpdateSwitch(toInt(newData), dispatch);
    setIsProcess(false);
    if (res.isOk) {
      const obj = Object.fromEntries(
        Object.entries(res.data.data)
          .map(([key, val]) => [key, !!parseInt(val)])
      );
      dispatch(userActions.updateUserInfo('user_kintone', obj));
    }
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer spacing={3} direction="row">
        <GridItem xs={12} md={12} lg={3}>
          <LeftMenu />
        </GridItem>
        <GridItem xs={12} md={12} lg={9}>
          <Basic />
          <Card id="auth">
            <CardHeader>
              <h3 className="text-bold text-dark-grey">認証情報</h3>
            </CardHeader>
            <CardBody className="pt-0">
              <Box sx={{ mt: 3 }}>
                <GridContainer spacing={3} direction="row">
                  <GridItem xs={12}>
                    <InputLabel className="text-grey">ドメイン</InputLabel>
                    <CustomInput
                      success={isEmpty(error.domain)}
                      error={!isEmpty(error.domain)}
                      helperText={error.domain}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        id: "domain",
                        placeholder: "https://example.cybozu.com",
                        onChange: (e) => handleBindValue(e),
                        maxLength: 255,
                        value: data.domain ?? "",
                        type: "text",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box sx={{ mt: 3 }}>
                <GridContainer spacing={3} direction="row">
                  <GridItem xs={6}>
                    <InputLabel className="text-grey">顧客アプリAPIトークン</InputLabel>
                    <CustomInput
                      success={isEmpty(error.cus_token)}
                      error={!isEmpty(error.cus_token)}
                      helperText={error.cus_token}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        id: "cus_token",
                        placeholder: "顧客アプリAPIトークンを入力してください",
                        onChange: (e) => handleBindValue(e),
                        maxLength: 255,
                        value: data.cus_token ?? "",
                        type: "text",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <InputLabel className="text-grey">顧客アプリID</InputLabel>
                    <CustomInput
                      success={isEmpty(error.cus_app_id)}
                      error={!isEmpty(error.cus_app_id)}
                      helperText={error.cus_app_id}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        id: "cus_app_id",
                        placeholder: "顧客アプリIDを入力してください",
                        onChange: (e) => handleBindValue(e),
                        value: data.cus_app_id ?? "",
                        type: "number",
                        max: 100,
                        min: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box sx={{ mt: 3 }}>
                <GridContainer spacing={3} direction="row">
                  <GridItem xs={6}>
                    <InputLabel className="text-grey">
                      通話履歴アプリAPIトークン
                    </InputLabel>
                    <CustomInput
                      success={isEmpty(error.his_token)}
                      error={!isEmpty(error.his_token)}
                      helperText={error.his_token}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        id: "his_token",
                        placeholder: "通話履歴アプリAPIトークンを入力してください",
                        onChange: (e) => handleBindValue(e),
                        maxLength: 255,
                        value: data.his_token ?? "",
                        type: "text",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <InputLabel className="text-grey">
                      通話履歴アプリID
                    </InputLabel>
                    <CustomInput
                      success={isEmpty(error.his_app_id)}
                      error={!isEmpty(error.his_app_id)}
                      helperText={error.his_app_id}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      id="his_app_id"
                      inputProps={{
                        id: "his_app_id",
                        placeholder: "通話履歴アプリIDを入力してください",
                        onChange: (e) => handleBindValue(e),
                        value: data.his_app_id ?? "",
                        type: "number",
                        max: 100,
                        min: 1,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box sx={{ mt: 3 }}>
                <GridContainer
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <GridItem>
                    <LoadingButton
                      color="secondary"
                      disabled={enableBtn()}
                      loading={isLoadingBtn}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      onClick={handleUpdate}
                    >
                      保存
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </Box>
            </CardBody>
          </Card>
          <Taishou
            is_cus_realtime_trans={data.is_cus_realtime_trans}
            is_cus_batch_trans={data.is_cus_batch_trans}
            is_cus_connect={data.is_cus_connect}
            is_his_connect={data.is_his_connect}
            is_his_realtime_trans={data.is_his_realtime_trans}
            is_his_batch_trans={data.is_his_batch_trans}
            connect_type={data.connect_type}
            company_id={data.company_id}
            isProcess={isProcess}
            onChange={(data) => handleUpdateSwitch(data)}
          ></Taishou>
        </GridItem>
      </GridContainer>
    </>
  );
}
