import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Check from "@material-ui/icons/Check";
import PersonIcon from "@material-ui/icons/Person";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import { Pagination } from "@material-ui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import styles from "assets/jss/salto-react/views/regularFormsStyle";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { cloneDeep, findIndex, has, isEqual } from "lodash";
import Moment from "moment";
import { headerTable } from "./HeaderTable";
import Spinner from "react-bootstrap/Spinner";
import { CSVLink } from "react-csv";
import { renderColorBadge } from "../../../helpers";
import { URL } from "../../../helpers/url";

import {
  cleanObject,
  selectedOptionById,
  getOnlyNumber,
  localDateTimeFormat,
} from "../../../helpers/common";
import { AlertConfirm, AlertProcess } from "../../../helpers/AlertProcess";
import {
  fetchCustomers,
  fetchCheckLimitSF,
  fetchBulkImportSF,
  fetchCheckLimitKT,
  fetchBulkImportKT,
  fetchCusExport,
  fetchUpdateSwitch,
} from "../../../services/api.customer";
import { CONSTANT } from "../../../constants/constant";

const useStyles = makeStyles(styles);

export default function CustomerListTable(props) {
  const { settingInfo, searchExtend, onLoading } =
    props;
  const classes = useStyles();
  const logonUser = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    name: "",
    customer_no: "",
    tel_filter: "",
    list_name: "",
    result_name: "",
    user_name: "",
  });
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderType, setOrderType] = useState("");
  const [selected, setSelected] = useState([]);
  const [notSelected, setNotSelected] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isProcess, setIsProcess] = useState(false);
  const [isProcessExport, setIsProcessExport] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingUpdateSF, setIsLoadingUpdateSF] = useState(false);
  const [isLoadingUpdateKT, setIsLoadingUpdateKT] = useState(false);
  const [exportCsv, setExportCsv] = useState({
    body: [],
    header: [],
  });
  const [currentParam, setCurrentParam] = useState({
    page: 1,
  });
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [alertConfirm, setAlertConfirm] = useState(null);
  const [alertProcess, setAlertProcess] = useState(null);
  const csvLink = React.createRef();
  const fileName = `customers_list_${logonUser.company_id}_${Moment().format("YYYYMMDDHHmmss")}.csv`;

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isNotSelected = (name) => notSelected.indexOf(name) !== -1;

  useEffect(() => {
    let current = true;
    if (current && !isEqual(currentParam, searchExtend)) {
      setCurrentParam(searchExtend);
      fetchApi();
    }

    return () => {
      current = false;
    };
  }, [searchExtend, currentParam]);

  useEffect(() => {
    let timeout;
    let current = true;
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    function fetchSearch() {
      if (current && !isFirstRun) {
        setPage(1);
        fetchApi();
      }
    }
    if (filter) {
      timeout = setTimeout(fetchSearch, 500);
    }
    return () => {
      current = false;
    };
  }, [filter]);

  useEffect(() => {
    if (isProcessExport) {
      setTimeout(() => {
        if (csvLink.current) {
          csvLink.current.link.click();
          setIsProcessExport(false);
        }
      });
    }
  }, [isProcessExport]);

  async function fetchApi(page = 1) {

    const params = cleanObject({
      ...searchExtend,
      ...filter,
      order_by: orderBy,
      order_type: orderType,
      page: page,
      rows_per_page: perPage,
      tel_for_find: getOnlyNumber(searchExtend.tel_for_find),
    });

    setIsProcess(true);
    const res = await fetchCustomers(params, dispatch);
    if (res.isOk) {
      setRowData(res.data.data);
      setTotalItems(res.data.page_meta.total_items);
    }

    setIsProcess(false);
    setIsFirstRun(false);
    onLoading(false);
  }

  useEffect(() => {
    if (!isFirstRun) {
      fetchApi();
    }
  }, [orderBy, orderType, perPage]);

  function handleChangePage(page) {
    setPage(page);
    fetchApi(page);
  }

  function getCleanParams() {
    const params = cleanObject({
      ...searchExtend,
      ...filter,
      selected: selected.join(","),
      not_selected: notSelected.join(","),
      is_check_all: +isCheckAll,
      rows_per_page: totalItems,
    });

    return params;
  }

  function handleConfirm(obj) {
    const { isProcess, type } = obj;
    setAlertConfirm(null);
    if (!isProcess) {
      setIsLoadingUpdateSF(false);
      setIsLoadingUpdateKT(false);
      setIsLoadingExport(false);
      return;
    }

    if (type === 1) {
      setAlertProcess(1);
      handleBulkImportKT();
    }

    if (type === 2) {
      setAlertProcess(2);
      handleBulkImportSF();
    }

    if (type === 3) {
      handleBulkExport();
    }
  }

  function getCheckLimitParam() {
    return {
      total_item: totalItems,
      selected: selected.join(","),
      not_selected: notSelected.join(","),
      is_check_all: +isCheckAll,
    };
  }

  async function handleCheckLimitKT() {
    setIsLoadingUpdateKT(true);

    const res = await fetchCheckLimitKT(getCheckLimitParam(), dispatch);
    if (!res.isOk) {
      return setIsLoadingUpdateKT(false);
    }

    setAlertConfirm(
      <AlertConfirm
        data={res.data}
        showConfirm={true}
        type={1}
        onClick={(obj) => handleConfirm(obj)}
      />
    );
  }

  async function handleBulkImportKT() {
    await fetchBulkImportKT(getCleanParams(), dispatch);
    setAlertProcess(null);
    setIsLoadingUpdateKT(false);
  }

  async function handleCheckLimitSF() {
    setIsLoadingUpdateSF(true);

    const res = await fetchCheckLimitSF(getCheckLimitParam(), dispatch);
    if (!res.isOk) {
      return setIsLoadingUpdateSF(false);
    }

    setAlertConfirm(
      <AlertConfirm
        data={res.data}
        showConfirm={true}
        type={2}
        onClick={(obj) => handleConfirm(obj)}
      />
    );
  }

  async function handleBulkImportSF() {
    await fetchBulkImportSF(getCleanParams(), dispatch);
    setAlertProcess(null);
    setIsLoadingUpdateSF(false);
  }

  function checkOverRecord(type = 3) {
    const updateItems = totalItems - notSelected.length;
    const check =
      (isCheckAll && updateItems > CONSTANT.LIMIT_EXPORT) ||
      selected.length > CONSTANT.LIMIT_EXPORT;

    return setAlertConfirm(
      <AlertConfirm
        data={{
          is_over: true,
          left: CONSTANT.LIMIT_EXPORT,
          update: isCheckAll ? updateItems : selected.length,
        }}
        showConfirm={!check}
        type={type}
        onClick={(obj) => handleConfirm(obj)}
      />
    );
  }

  async function handleCheckLimitExport() {
    return checkOverRecord();
  }

  async function handleBulkExport() {
    setIsLoadingExport(true);
    const res = await fetchCusExport(getCleanParams(), dispatch);
    setAlertProcess(null);
    setIsLoadingExport(false);

    if (!res.isOk) {
      return setAlertProcess(null);
    }
    setExportCsv(res.data);
    setIsProcessExport(true);
  }

  async function handleChangeStatus(customer_no, st) {
    const status = st ? st["id"] : null;
    const index = findIndex(rowData, { customer_no });
    let newRowData = cloneDeep(rowData);
    newRowData[index]["status"] = status;
    setRowData(newRowData);

    await fetchUpdateSwitch({ customer_no, status }, dispatch);
  }

  function createSortHandler(key) {
    const isAsc = orderBy === key && orderType === "asc";
    setOrderType(isAsc ? "desc" : "asc");
    setOrderBy(key);
  }

  function handleCheckOnGrid(id) {
    const data = isCheckAll ? notSelected : selected;
    const selectedIdx = data.indexOf(id);
    let items = [...data];

    if (selectedIdx === -1) {
      items.push(id);
    }

    if (selectedIdx > -1) {
      items.splice(selectedIdx, 1);
    }

    isCheckAll ? setNotSelected(items) : setSelected(items);
    if (items.length === totalItems) {
      setIsCheckAll(isCheckAll ? false : true);
      setSelected([]);
      setNotSelected([]);
    }
  }

  function handleCheckAll(e) {
    setIsCheckAll(e.target.checked ? true : false);
    setSelected([]);
    setNotSelected([]);
  }

  function checkIsChecked() {
    return isCheckAll || selected.length || notSelected.length;
  }

  // Function generate row search for table
  function searchData() {
    return [
      "",
      "name",
      "customer_no",
      "tel_filter",
      "list_name",
      "",
      "result_name",
      "",
      "user_name",
      "",
    ].map((item, idx) => {
      if (item === "") {
        return <TableCell key={idx}></TableCell>;
      }

      if (item === "result_name") {
        return (
          <TableCell key={idx} className="result-name-filter">
            <Autocomplete
              key="result_name"
              className="custom-autocomplete"
              disablePortal
              options={settingInfo.result_pattern.filter(item => item.isChecked === 1) || []}
              getOptionLabel={(item) => item.name}
              onChange={(_e, newValue) => {
                setFilter((prevState) => ({
                  ...prevState,
                  [item]: newValue ? newValue.result_pattern_id : "",
                }));
              }}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </TableCell>
        );
      }

      return (
        <TableCell key={idx}>
          <CustomInput
            key={item}
            formControlProps={{
              fullWidth: true,
              className: "input-search",
            }}
            inputProps={{
              onChange: (e) => {
                setFilter((prevState) => ({
                  ...prevState,
                  [item]: e.target.value,
                }));
              },
              type: "text",
              value: filter[item] ? filter[item] : "",
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
      );
    });
  }

  function getLink(row, key, isDate = false) {
    const url = `${CONSTANT.PREFIX_ADMIN}${URL.CUSTOMER}/${row.customer_no}`;
    return <Link to={url}>{!isDate ? row[key] : localDateTimeFormat(row[key])}</Link>;
  }

  // Render row data of table
  function renderRow() {
    if (isProcess) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={headerTable.length}>
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
          </TableCell>
        </TableRow>
      );
    }

    if (!rowData.length) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={headerTable.length + 1}>
            <div style={{ width: "100%", textAlign: "center" }}>
              該当データが存在しません。
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return rowData.map((row, idx) => {
      const isItemSelected = isSelected(row.customer_no);
      const isItemNotSelected = isNotSelected(row.customer_no);
      const labelId = `enhanced-table-checkbox-${idx}`;

      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={idx}
          selected={(isCheckAll || isItemSelected) && !isItemNotSelected}
        >
          <TableCell padding="checkbox" className="checkbox-circle-20">
            <Checkbox
              checkedIcon={<Check className={"checked-icon"} />}
              icon={<Check className={classes.uncheckedIcon} />}
              color="primary"
              onClick={() => handleCheckOnGrid(row.customer_no)}
              checked={
                (isCheckAll || isItemSelected) &&
                !isItemNotSelected &&
                !(has(row, "status") && row.current_status === false)
              }
              disabled={has(row, "status") && row.current_status === false}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
          <TableCell className="text-gray">{getLink(row, "name")}</TableCell>
          <TableCell className="text-gray">
            {getLink(row, "customer_no")}
          </TableCell>
          <TableCell className="text-gray">{getLink(row, "tel")}</TableCell>
          <TableCell className="text-gray">
            {getLink(row, "list_name")}
          </TableCell>
          <TableCell className="text-gray">
            {getLink(row, "renkei_date", true)}
          </TableCell>
          <TableCell className="text-gray">
            <Box sx={{ display: "flex" }} alignItems="center">
              <Badge
                color="primary"
                badgeContent={row.result_name}
                className={
                  "customer-list-badge-" + renderColorBadge(row.result)
                }
              ></Badge>
            </Box>
          </TableCell>
          <TableCell className="text-gray">
            <div className="result-name-filter">
              <Autocomplete
                key={idx}
                className="custom-autocomplete"
                disablePortal
                value={selectedOptionById(settingInfo.status, row.status)}
                getOptionLabel={(option) => option.status || ""}
                options={settingInfo.status}
                onChange={(_e, newValue) => {
                  newValue !== undefined &&
                    handleChangeStatus(row.customer_no, newValue);
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} key={idx} />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.status}
                  </Box>
                )}
              />
            </div>
          </TableCell>
          <TableCell className="text-gray">
            <Box sx={{ display: "flex" }} alignItems="center">
              <PersonIcon />
              {row.user_name}
            </Box>
          </TableCell>
          <TableCell className="text-gray">
            <Box className="btn-action-customer-list">
              <Tooltip title="対応履歴" placement="top">
                <Button className="btn-detail" justIcon>
                  <Link
                    to={`${CONSTANT.PREFIX_ADMIN}${URL.CUSTOMER}/${row.customer_no}`}
                  >
                    <i className="material-icons text-sm">support_agent</i>
                  </Link>
                </Button>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Card className="customer-list">
      {alertConfirm}
      {alertProcess && <AlertProcess type={alertProcess} />}
      <CSVLink
        ref={csvLink}
        data={exportCsv.body}
        asyncOnClick={true}
        headers={exportCsv.header}
        filename={fileName}
        target="_blank"
      />
      <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <h4 className="text-bold">顧客一覧</h4>
            <p className="text-grey">
              デフォルトは本日分のEverCall顧客データが表示されています。（最大1万件）
              <br />
              もっと見たい場合は検索条件を指定してください。
            </p>
          </GridItem>
          <GridItem xs={6} align="right">
            <Button
              className="btn-kintone"
              onClick={handleCheckLimitKT}
              color="rose"
              disabled={!(logonUser?.user_kintone?.is_cus_connect && checkIsChecked()) || isLoadingUpdateKT}
            >
              キントーン連携
              {isLoadingUpdateKT ? (
                <CircularProgress className="icon-loading" color="inherit" />
              ) : (
                ""
              )}
            </Button>
            <Button
              className="btn-salesforce"
              onClick={handleCheckLimitSF}
              disabled={!(logonUser?.user_salesforce?.is_cus_connect && checkIsChecked()) || isLoadingUpdateSF}
            >
              SalesForce連携
              {isLoadingUpdateSF ? (
                <CircularProgress className="icon-loading" color="inherit" />
              ) : (
                ""
              )}
            </Button>
            <MuiButton
              className="btn-export"
              variant="outlined"
              color="secondary"
              onClick={handleCheckLimitExport}
              disabled={!checkIsChecked() || isLoadingExport}
            >
              Export
              {isLoadingExport ? (
                <CircularProgress className="icon-loading" color="inherit" />
              ) : (
                ""
              )}
            </MuiButton>
          </GridItem>
          <GridItem xs={12}>
            <Paper className="data-table">
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="customer-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        className="checkbox-circle-20"
                      >
                        <Checkbox
                          checkedIcon={<Check className={"checked-icon"} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          indeterminateIcon={
                            <Remove className={"indeterminate-icon"} />
                          }
                          color="primary"
                          indeterminate={
                            (selected.length > 0 &&
                              selected.length < totalItems &&
                              !isCheckAll) ||
                            (isCheckAll && notSelected.length > 0)
                          }
                          checked={
                            (rowData.length > 0 &&
                              selected.length === totalItems) ||
                            isCheckAll
                          }
                          onChange={handleCheckAll}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                      {headerTable.map((column) => (
                        <TableCell
                          key={column.key}
                          padding="normal"
                          sortDirection={
                            orderBy == column.key ? orderType : false
                          }
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={orderBy === column.key}
                              direction={
                                orderBy === column.key ? orderType : "asc"
                              }
                              onClick={() => createSortHandler(column.key)}
                            >
                              {column.label}
                              {orderBy === column.key ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {orderType === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>{searchData()}</TableRow>
                    {renderRow()}
                  </TableBody>
                </Table>
              </TableContainer>
              {rowData.length > 0 && (
                <GridContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <GridItem sx={6}>
                    {`Showing ${(page - 1) * perPage + 1
                      } to
                  ${rowData.length +
                      (page - 1) * perPage
                      } of ${totalItems}
                  entries`}
                  </GridItem>
                  <GridItem sx={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      align="right"
                    >
                      <TablePagination
                        className="hide-pagination"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={perPage}
                        page={0}
                        onPageChange={(_e, val) =>
                          setPerPage(val)
                        }
                        onRowsPerPageChange={(e) =>
                          setPerPage(e.target.value)
                        }
                      />
                      <Pagination
                        count={
                          Math.ceil(totalItems / perPage) > 0
                            ? Math.ceil(totalItems / perPage)
                            : 1
                        }
                        page={page}
                        className="pagination-rose"
                        onChange={(_e, val) =>
                          handleChangePage(val)
                        }
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
              )}
            </Paper>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
