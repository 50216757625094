import React from "react";
import { Box, Checkbox, RadioGroup, Radio, FormControlLabel, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Check } from "@material-ui/icons";
import { Autocomplete, TextField } from "@mui/material";
import styles from "assets/jss/salto-react/views/regularFormsStyle";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isEmpty } from "lodash";
import Datetime from "react-datetime";
import { dateFormat } from "../../../helpers/common";


const useStyles = makeStyles(styles);

export default function BatchSetting(props) {
  const { model, errors, onChange } = props;
  const classes = useStyles();
  const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  const minutes = ["10", "20", "30", "40", "50", "60"];
  const hours = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
  const schedule = model?.schedule ? model.schedule : 1;

  function handleCheck(e, idx) {
    const { name, checked } = e.target;
    const newCheckbox = model.weekday.split(",");
    newCheckbox[idx] = checked ? 1 : 0;
    onChange({ target: { name, value: newCheckbox.map(x => +x).join(",") } });
  }

  const dayText = ["月", "火", "水", "木", "金", "土", "日"];

  return (
    <GridItem xs={12}>
      <Box sx={{ py: 2 }} >
        <RadioGroup
          row
          value={schedule}
          onChange={(e) => onChange(e)}
        >
          <Tooltip
            arrow
            className="tooltip-bubble"
            title="設定日で定期実行を一回のみ実行する"
            placement="bottom"
          >
            <FormControlLabel
              value={1}
              control={<Radio name="schedule" />}
              label="一回のみ"
              className="text-grey"
            />
          </Tooltip>
          {/* <Tooltip
            arrow
            className="tooltip-bubble"
            title="設定分数ごとに定期実行を実行する"
            placement="bottom"
          >
            <FormControlLabel
              value={2}
              control={<Radio name="schedule" />}
              label="分"
              className="text-grey"
            />
          </Tooltip> */}
          <Tooltip
            arrow
            className="tooltip-bubble"
            title="設定された間隔ごとに実行を繰り返す"
            placement="bottom"
          >
            <FormControlLabel
              value={2}
              control={<Radio name="schedule" />}
              label="繰返（分）"
              className="text-grey"
            />
          </Tooltip>
          <Tooltip
            arrow
            className="tooltip-bubble"
            title="毎日、設定時間に定期実行を実行する"
            placement="bottom"
          >
            <FormControlLabel
              value={3}
              control={<Radio name="schedule" />}
              label="毎日"
              className="text-grey"
            />
          </Tooltip>
          <Tooltip
            arrow
            className="tooltip-bubble"
            title="毎週、設定曜日に定期実行を実行する"
            placement="bottom"
          >
            <FormControlLabel
              value={4}
              control={<Radio name="schedule" />}
              label="毎週"
              className="text-grey"
            />
          </Tooltip>
          <Tooltip
            arrow
            className="tooltip-bubble"
            title="毎月、設定日に定期実行を実行する"
            placement="bottom"
          >
            <FormControlLabel
              value={5}
              control={<Radio name="schedule" />}
              label="毎月"
              className="text-grey"
            />
          </Tooltip>
        </RadioGroup>
      </Box>
      {schedule === 1 && (
        <Box sx={{ py: 2 }}>
          <GridContainer>
            <GridItem xs={6}>
              <InputLabel className="text-grey">実行日</InputLabel>
              <CustomInput
                success={isEmpty(errors?.start_date)}
                error={errors?.start_date ? true : false}
                type="datetime"
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                closeOnSelect={false}
                helperText={errors?.start_date}
                formControlProps={{ fullWidth: true }}
                onDateChange={(e) => onChange(e)}
                inputProps={{
                  name: "start_date",
                  placeholder: "YYYY-mm-dd HH:mm",
                  maxLength: 255,
                  value: model?.start_date ? dateFormat(model?.start_date) : "",
                  type: "text",
                  autoComplete: "off"
                }}
              />
            </GridItem>
          </GridContainer>
        </Box>
      )}

      {/* {schedule === 2 && (
        <Box sx={{ py: 2 }}>
          <GridContainer>
            <GridItem xs={6}>
              <InputLabel className="text-grey">分</InputLabel>
              <CustomInput
                success={isEmpty(errors?.minute)}
                error={errors?.minute ? true : false}
                helperText={errors?.minute}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: "minute",
                  type: "number",
                  maxLength: 255,
                  value: model?.minute ?? 0,
                  onChange: (e) => onChange(e),
                }}
              />
            </GridItem>
          </GridContainer>
        </Box>
      )} */}
      {schedule === 2 && (
        <Box sx={{ py: 2 }}>
          <GridContainer>
            <GridItem xs={4}>
              <Autocomplete
                disablePortal
                value={model?.minute ? model?.minute.toString() : ''}
                options={minutes}
                renderInput={(params) => {
                  return (
                    <TextField
                      variant="standard"
                      type={"number"}
                      {...params}
                      placeholder="分数を選択してください"
                    />
                  );
                }}
                onChange={(_e, value) =>
                  onChange({ target: { name: "minute", value: value ?? "" } })
                }
              />
            </GridItem>
            <GridItem xs={4}>
              <Autocomplete
                disablePortal
                value={model?.start_hour ? model?.start_hour.toString() : ''}
                options={hours}
                renderInput={(params) => {
                  return (
                    <TextField
                      variant="standard"
                      type={"number"}
                      {...params}
                      placeholder="開始時点(時間)"
                    />
                  );
                }}
                onChange={(_e, value) =>
                  onChange({ target: { name: "start_hour", value: value ?? "" } })
                }
              />
            </GridItem>
            <GridItem xs={4}>
              <Autocomplete
                disablePortal
                value={model?.end_hour ? model?.end_hour.toString() : ''}
                options={hours}
                renderInput={(params) => {
                  return (
                    <TextField
                      variant="standard"
                      type={"number"}
                      {...params}
                      placeholder="停止時点(時間)"
                    />
                  );
                }}
                onChange={(_e, value) =>
                  onChange({ target: { name: "end_hour", value: value ?? "" } })
                }
              />
            </GridItem>
          </GridContainer>
        </Box>
      )}
      {schedule === 3 && (
        <Box sx={{ py: 2 }}>
          <GridContainer>
            <GridItem xs={4}>
              <Datetime
                value={model?.hour ? model?.hour.substr(0, 5) : ""}
                inputProps={{
                  placeholder: "hh:mm",
                }}
                renderInput={(params) => {
                  return <TextField
                    helperText={errors?.hour}
                    variant="standard" {...params}
                    error={errors?.hour ? true : false}
                  />;
                }}
                timeFormat="HH:mm"
                dateFormat={false}
                closeOnSelect
                onChange={(value) => onChange({ target: { name: "hour", value } })}
              />
            </GridItem>
          </GridContainer>
        </Box>
      )}
      {schedule === 4 && (
        <Box sx={{ py: 2 }}>
          <GridContainer>
            <GridItem xs={8}>
              {model.weekday.split(",").map((item, idx) => (
                <FormControlLabel
                  key={idx}
                  className="checkbox-circle-20"
                  control={
                    <Checkbox
                      checkedIcon={<Check className={"checked-icon"} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                      onChange={(e) => handleCheck(e, idx)}
                      checked={parseInt(item) === 1 ? true : false}
                      name="weekday"
                    />
                  }
                  label={dayText[idx]}
                />
              ))
              }
            </GridItem>
            <GridItem xs={4}>
              <Datetime
                value={model?.hour ? model?.hour.substr(0, 5) : ""}
                inputProps={{
                  placeholder: "hh:mm",
                }}
                renderInput={(params) => {
                  return <TextField
                    helperText={errors?.hour}
                    variant="standard" {...params}
                    error={errors?.hour ? true : false}
                  />;
                }}
                timeFormat="HH:mm"
                dateFormat={false}
                closeOnSelect
                onChange={(value) => onChange({ target: { name: "hour", value } })}
              />
            </GridItem>
          </GridContainer>
        </Box>
      )}
      {schedule === 5 && (
        <Box sx={{ py: 2 }}>
          <GridContainer>
            <GridItem xs={8}>
              <Autocomplete
                disablePortal
                value={model?.day ? model?.day.toString() : "1"}
                options={days}
                renderInput={(params) => {
                  return <TextField variant="standard" type={"number"} {...params} />;
                }}
                onChange={(_e, value) => onChange({ target: { name: "day", value: value ?? "" } })}
              />
            </GridItem>
            <GridItem xs={4}>
              <Datetime
                value={model?.hour ? model?.hour.substr(0, 5) : ""}
                inputProps={{
                  placeholder: "hh:mm",
                }}
                renderInput={(params) => {
                  return <TextField
                    helperText={errors?.hour}
                    variant="standard" {...params}
                    error={errors?.hour ? true : false}
                  />;
                }}
                timeFormat="HH:mm"
                dateFormat={false}
                closeOnSelect
                onChange={(value) => onChange({ target: { name: "hour", value } })}
              />
            </GridItem>
          </GridContainer>
        </Box>
      )}
    </GridItem>
  );
}
